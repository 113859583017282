import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-produto-regiao-destaque',
  templateUrl: './produto-regiao-destaque.component.html',
  styleUrls: ['./produto-regiao-destaque.component.scss']
})
export class ProdutoRegiaoDestaqueComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template = {};

  constructor() { }

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
