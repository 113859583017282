import { Component, OnInit } from '@angular/core';
import { ConteudoService } from '../../services/conteudo.service';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'servico-categoria-page',
  templateUrl: './servico-categoria-page.component.html',
  styleUrls: ['./servico-categoria-page.component.scss']
})
export class ServicoCategoriaPage implements OnInit {

  public loader   = false;
  private apelido = "servicos";
  public response:any = {
    data: "",
    status: null
  };
  public page     = null;
  public servicos = [];
  public itensBreadcrumbs = [{
    text: "Serviços",
    routerLink: "servicos"
  }];
  public produtos         = [];
  public categorias       = [];
  public produtosOriginal = [];
  public paramsSearch     = {
    nome: "",
    produto_categoria_id: ""
  }

  constructor(
    private conteudo:ConteudoService,
    private app: AppService
  ){ }
  /**
   * 
   * 
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true;
      
      this.conteudo.getPageByApelido(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page             = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.produtosOriginal = Object.assign([],response.data.page.produtos);
            this.produtos         = response.data.page.produtos;
            this.categorias       = response.data.page.categorias;
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
              
          }
          this.response = {
            data: "",
            status: 1
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /***
   * 
   * Change
   * 
   */
  _change(dataItem){

    this.paramsSearch = dataItem;
    this._search();

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  _search(){ 

    let self     = this;
    let data:any = [];
    let status   = false;
    let nome     = ""; 

    for(let index = 0; index < this.produtosOriginal.length; index++) {
        
      status = false; 
      nome   = this.app.formatarApelido(this.produtosOriginal[index].nome,false);

      if(this.paramsSearch.nome !== ""){ 

        if(nome.indexOf(this.app.formatarApelido(this.paramsSearch.nome,false)) != -1){
          status = true;
        }

      }else{
        status = true; 
      }

      if(this.paramsSearch.produto_categoria_id !== ""){

        if(this.paramsSearch.nome !== ""){

          if((this.produtosOriginal[index].produto_categoria_id == this.paramsSearch.produto_categoria_id) && (nome.indexOf(this.paramsSearch.nome.toLowerCase()) != -1)){
            status = true; 
          }else{
            status = false;
          }

        }else{

          if(this.produtosOriginal[index].produto_categoria_id == this.paramsSearch.produto_categoria_id){
            status = true;
          }else{
            status = false;
          }

        }
        

      } 
      if(status){
        data.push(this.produtosOriginal[index]);
      }  
        
    }

    this.produtos = data;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.getData();
  }

}
