import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { map,startWith } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'evento-form-search',
  templateUrl: './evento-form-search.component.html',
  styleUrls: ['./evento-form-search.component.scss']
})
export class EventoFormSearchComponent implements OnInit {

  public dataItem:any  = {};
  @Input("idCidade") idCidade       = null; 
  @Input("produtos") produtos       = []; 
  @Input("apelidoDica") apelidoDica = null; 
  @Input("apelidoCategoria") apelidoCategoria = null; 
  @Input("termo") termo             = null; 
  @Input("categorias") categorias   = [];  
  @Input("classificacao") classificacao = [];
  @Input("template")     template  = "one"; 
  @Output("updateData") updateData = new EventEmitter(); 
  @Output("change") _change        = new EventEmitter();
  public myControl                 = new UntypedFormControl();
  public options:string[]          = [];
  public filteredOptions:Observable<string[]>;
  @ViewChild("auto") auto:MatAutocomplete;
  public loader = false;
  
  constructor(
    private api: ApiService
  ){ 
    this.initDataItem();
  }

  /**
   * 
   * Pesquisar
   * 
   * 
   */
  pesquisar(){

    try{

      this.loader = true;

      this.api.evento().search(this.dataItem).subscribe((response) => {

        this.loader = false;

        if(response.status == 1){
          this.updateData.emit(response.data);
        }  

      },(response) => {

        this.loader = false;
        
      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      tipo_periodo_id: "",
      produto_cidade_id: this.idCidade,
      produto_categoria_id: "",
      produto_classificacao_id: "",
      termo: this.termo,
      preco: "",
      apelido_dica: this.apelidoDica,
      apelido_categoria: this.apelidoCategoria
    }

  }
  /**
   * 
   * Filter
   * 
   * @param value 
   * 
   */
  private _filter(value: string): string[] {
    
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  
  }
  /***
   * 
   * Set Options
   * 
   */
  setOptions(id=null){

    this.options = [];

    for(let index = 0; index < this.produtos.length; index++) {
      
      if(id == null){
        this.options.push(this.produtos[index].nome); 
      }else if(id == this.produtos[index].produto_categoria_id){
        this.options.push(this.produtos[index].nome); 
      }  
      
    }
      

  }
  _changeInput(){

    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Categoria
   * 
   */
  _changeCategoria(){

    this.dataItem.nome = "";
    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Categoria
   * 
   */
  _changeRegiao(){

    this.dataItem.nome = "";
    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Filtered
   * 
   */
  _filtered(){
    
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    
    this.setOptions();

    this.myControl.valueChanges.subscribe(data => {
      this._changeInput();
    });
    this._filtered();
         
  }
  /**
   * 
   * On Changes
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any): void {
   
    if(changes.termo){
      this.dataItem.termo = changes.termo.currentValue;
    }

  }

}
