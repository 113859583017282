import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'galeria-two',
  templateUrl: './galeria-two.component.html',
  styleUrls: ['./galeria-two.component.scss']
})
export class GaleriaTwoComponent implements OnInit {

  @Input("fotos") fotos = [];
  public indexSelected  = 0;

  constructor() { }
  
  /**
   * 
   * Seta o index
   * 
   */
  setIndex(index){

    this.indexSelected = index;  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
