import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'evento-hotel-passageiros',
  templateUrl: './evento-hotel-passageiros.component.html',
  styleUrls: ['./evento-hotel-passageiros.component.scss'] 
})
export class EventoHotelPassageirosComponent implements OnInit {

  @Input("data") data                    = [];
  @Output("updateData") updateData       = new EventEmitter();
  @Output("updateTitular") updateTitular = new EventEmitter();
  public locale                          = "pt"; 

  constructor(
    private app: AppService 
  ){

    this.locale  = this.app.getLocale(); 

  }
  /**
   * 
   * Seta os dados
   * 
   */
  setData(){

    let titular = true;

    for(let index = 0; index < this.data.length; index++) {
      
      this.data[index].passageiros = [];

      for(let y = 0;y < this.data[index].numero_pessoas;y++) {

        this.data[index].passageiros.push({ 
          nome: "",
          cpf: "",
          rg: "",
          email: "",
          telefone: "",
          data_nascimento: "",
          observacoes: "",
          titular: titular,
          tipo: "adt",
          passaporte: ""
        });
        titular = false; 

      } 
      /*for(let y = 0;y < this.data[index].quantidade_chd;y++) {

        this.data[index].passageiros.push({
          nome: "",
          cpf: "",
          rg: "",
          email: "",
          telefone: "",
          data_nascimento: "",
          observacoes: "",
          titular: titular,
          tipo: "chd",
        });

      }*/
      
    }
    this.updateData.emit(this.data);

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.app.emitChangeHoteis.subscribe(data => {

      this.data = data;
      this.setData();

    }); 

  }
  /***
   * 
   * Seta titular
   * 
   */
  setTitular(passageiro){  
        
    for(let index = 0; index < this.data.length; index++) {
      
      for(let i = 0;i < this.data[index].passageiros.length;i++) {
        this.data[index].passageiros[i].titular = false; 
      }
      
    }  
    passageiro.titular = true;  
    
  }
  /**
   * 
   * Change input
   * 
   */
  _changeInput(passageiro){

    if(passageiro.titular){

      this.updateTitular.emit(passageiro);

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.onData();
    this.setData();
  }

}
