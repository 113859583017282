import { Directive, ElementRef, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR,DefaultValueAccessor} from "@angular/forms";

const INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UppercaseDirective),
  multi: true,
};

@Directive({
  selector: '[uppercase]',
  providers: [
    INPUT_CONTROL_VALUE_ACCESSOR,
  ],
  host: {
    '(input)': 'onInput($event.target.value)',
    '(blur)': 'onTouched()',
  },
})
export class UppercaseDirective extends DefaultValueAccessor {

  constructor(
    private renderer2: Renderer2,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    super(renderer2,el,false);
  }

  onInput(){
    
    let value = this.formatValue(this.el.nativeElement.value);
    super.writeValue(value);
    this.onChange(value);

  }
  private formatValue(value: any):any{

    return value.toUpperCase();

  }

}
