import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'modulo-produto-lista-categorias',
  templateUrl: './produto-lista-categorias.component.html',
  styleUrls: ['./produto-lista-categorias.component.scss']
})
export class ProdutoListaCategoriasComponent implements OnInit {
  
  @Input("data") data;
  @Input("template") template = null;
  public tela = {
    altura: null,
    largura: null
  }
  public largura = [
    0,
    0,
    0
  ];

  constructor(){ }
  
  @HostListener('window:resize', ['$event'])
  onResize() {
    
    this.tela.altura  = window.innerHeight;
    this.tela.largura = window.innerWidth;
    
    if(this.tela.largura > this.tela.altura){
      //this.altura = this.tela.altura+"px";
    }else if(this.tela.largura <= 480){
      //this.altura = "200px";
    }else{
      //this.altura = "300px";
    }
    
    let largura = this.tela.largura / 3;

    if(largura > 0){

      let sobra = largura*0.01099;

      largura = largura-sobra;
      
      this.largura[0] = largura-120;
      this.largura[1] = largura+240;
      this.largura[2] = largura-120;
      

    }
  
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.onResize();
  }

}
