import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], filter: object): any {
    /*if(!items || !filter) {
      return items;
    }*/
    //console.log(filter);
    //return items.filter(item => JSON.stringify(item).toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    /*if (!items || !filter) {
      return items;
    }*/
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item) => this.applyFilter(item, filter));
  }

  applyFilter(item,filter): boolean {
    
    for (let field in filter) {
      //if (filter[field]) {
        if (typeof filter[field] === 'string') {
          if (item[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
            return false;
          }
        } else if (typeof filter[field] === 'number') {
          if (item[field] !== filter[field]) {
            return false;
          }
        }else if (typeof filter[field] === 'boolean'){
          if (item[field] !== filter[field]) {
            return false;
          }
        }
      //}
    }
    return true;
  }

}
