import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'banner-slider-slick',
  templateUrl: './banner-slider-slick.component.html',
  styleUrls: ['./banner-slider-slick.component.scss']
})
export class BannerSliderSlickComponent implements OnInit,AfterViewInit {
  
  @Input("data")  data = null;
  @ViewChild("banner") banner:ElementRef;
  public banners       = [];

  constructor(){}

  /**
   * 
   * Inicializa o banner
   * 
   */
  initBanner(){

    $(this.banner.nativeElement).slick({
      infinite: true,
      autoplay: true,
      dots: true
    });
    $(this.banner.nativeElement).slick('refresh');

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkCategoria(banner){

    return "/servicos/"+banner.categoria;

  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
  }
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    this.initBanner();
  }

}
