import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'evento-page',
  templateUrl: './evento-page.component.html',
  styleUrls: ['./evento-page.component.scss']
})
export class EventoPage implements OnInit { 

  public clearInput           = new EventEmitter();
  public clearInputTransporte = new EventEmitter();
  public apelido              = null;
  public loader               = false;
  public itensBreadcrumbs     = [];
  public response      = {
    data: "",
    status: null
  }
  public evento       = null;
  public content      = 1;
  public hospedagens  = null;
  public transportes  = null;
  public passeios     = null;
  public dataItem:any = null;
  public locale       = "pt";
  public successSend  = false;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private app: AppService
  ){ 

    this.apelido = typeof(this.route.params["_value"].apelido) != "undefined" ? this.route.params["_value"].apelido : null;
    this.locale  = this.app.getLocale();
      
  }
  
  /**
   * 
   * Retorna os dados do produto baseado no apelido
   * 
   * 
   */
  getData(){

    try{

      this.loader           = true;
      this.itensBreadcrumbs = [];

      this.api.evento().evento(this.apelido).subscribe(response => {

        if(response.status == 1){

          this.evento = response.data.evento;
          
          if(response.data.evento == null){
            this.router.navigateByUrl("/");
          }
          
          this.hospedagens = response.data.hospedagens;
          this.transportes = response.data.transportes; 
          this.passeios    = response.data.passeios; 

          this.app.setMetaDados(this.evento.meta_title,this.evento.meta_description);
          this.app.toTop(); 
          this.setItensBreadcrumbs();
          this.initDataItem(); 
             
        }else{
          this.response = response;
        }

        setTimeout(() => {
          this.loader = false;
        },10);

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);

        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader = false;

    }

  }
  /***
   * 
   * Seta os breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Eventos",
      routerLink: "/eventos"
    });
    
    this.itensBreadcrumbs.push({
      text: this.evento.nome,
      routerLink: "" 
    });

  }
  /**
   * 
   * Seta content
   * 
   */
  setContent(value,id=null){

    this.content = value == this.content ? null : value;

    setTimeout(() => {

      if(id != null){ 
        
        //this.app.toSection(id);
      }

    },200);

  }
  /**
   * 
   * Change Hotel
   * 
   */
  changeHotel(data){


    this.dataItem.hotel_id  = data.hotel.id;
    this.dataItem.hotel     = data.hotel;
    this.dataItem.quartos   = data.quartos;

    this.clearInput.emit(this.dataItem.hotel_id);
    this.content = null;

  }
  /**
   * 
   * Change Hotel
   * 
   */
  changePasseios(data){

    this.dataItem.passeios.push({
      id: data.passeio.id,
      nome: data.passeio.nome, 
      valor: data.passeio.valor,
      quantidade: data.quantidade 
    });

    this.content = null;

  }
  /**
   * 
   * Change transportes
   * 
   */
  changeTransporte(data){
 
    this.dataItem.transportes.push({
      id: data.transporte.id,
      titulo: data.transporte.titulo,
      transporte_tipo_id: data.transporte.transporte_tipo_id,
      descricao: data.transporte.descricao,
      voo_data: data.voo_data,
      voo_numero: data.voo_numero,
      voo_hora: data.voo_hora,
      valor: data.transporte.valor,
      quantidade: data.quantidade,
      tipo: data.transporte.tipo
    }); 

    //this.clearInputTransporte.emit(this.dataItem.transporte_id);
    this.content = null;

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      cpf: "",
      passaporte: "",
      cartao_nome: "",
      cartao_numero: "",
      cartao_validade: "",
      cartao_codigo_seguranca: "",
      cartao_parcelas: 1,
      hotel_id: null,
      hotel: null,
      quartos: null,
      transportes: [],
      passeios: [],
      evento_id: this.evento.id 
    }

  }
  /**
   * 
   * Enviar
   * 
   */
  enviar(){

    try{

      this.loader      = true;
      this.successSend = false;

      this.api.pedido().finalizar_pedido(this.dataItem).subscribe(response => {

        this.loader = false;
        
        if(response.status == 1){

          this.successSend = true;
          setTimeout(() => {
            this.app.toSection("#section-pagamento-sucesso");
          },500);
          

        }else{

          this.app.info("Houve algo inesperado. Tente novamente.","danger");

        }

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);

        this.app.info(error.message,"danger");

      });

    }catch(e){

      this.loader = false;

    }

  }
  _delete(apelido,index=null,confirm=true){

    setTimeout(() => {
      this.content = null;
    },100);

    if(confirm){

      let message = "";

      if(this.locale == "pt"){

        message = "Deseja excluir o serviço ?";

      }else{

        message = "Do you want to delete the service?";

      }

      this.app.confirm(
        message,
        this.delete, 
        this,
        {
          apelido: apelido,
          index: index
        }
      );
      return false;
    }

  }
  /**
   * 
   * Delete
   * 
   * @param data 
   * 
   */
  delete(data){

    let self = data.self;
    data     = data.dataItem;

    switch(data.apelido){
      case "hotel":
        self.dataItem.hotel   = null;
        self.dataItem.quartos = [];
      break;
      case "traslado":
        self.transportes.splice(data.index,1); 
      break;
      case "passeio":
        self.passeios.splice(data.index,1); 
      break;
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){

    this.getData();
    
    
  }

}
