import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-destinos',
  templateUrl: './destinos.component.html',
  styleUrls: ['./destinos.component.scss']
})
export class DestinosComponent implements OnInit,AfterViewInit{
  
  @Input("data") data;
  @Input("template") template;
  @ViewChild("slider") slider:ElementRef;
  public dataItem:any = {};
  public loader       = false;
  public destinos     = [];
  public sliderContent = null;

  constructor() { 

  }

  /**
   * 
   * Seta os destinos
   * 
   */
  setDestinos(){

    this.destinos = this.data.cidades; 

  }
  /**
   * 
   * Init Banner
   * 
   */
  initBanner(){

    this.sliderContent = $(this.slider.nativeElement).owlCarousel({
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 4000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        800:{
          items:3
        },
        1000: {
          items: 5,
          nav: false,
          loop: true,
          margin: -15
        }
      }
    });
    
  
  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit(){
    this.setDestinos();
  }
  ngAfterViewInit(): void {
    this.initBanner();
  }

}
