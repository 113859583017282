import { ApiModulosSettings } from './../class/api-modulos-settings';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ModulosService {

  public updateModulosEmitter = new EventEmitter();
  public getModulosEmitter    = new EventEmitter();

  constructor(private http: HttpClient){

  }
  /**
   * 
   * Busca os módulos
   * 
   * 
   */
  getModulos():any{

    let url = ApiModulosSettings.API_MODULOS;

    return this.http.get(url)

  }
  /**
   * 
   * Send Contato
   * 
   * 
   */
  sendContatoViagem(params):any{
    
    let url = ApiModulosSettings.CONTATO.contato_viagem;
    
    return this.http.post(url,params)
    
  }
  /**
   * 
   * Send Contato
   * 
   * 
   */
  sendContato(params):any{
    
    let url = ApiModulosSettings.API_MODULO_CONTATO_SEND;
    
    return this.http.post(url,params)
    
  }
  /**
   * 
   * Send Newsletter
   * 
   * 
   */
  sendNewsletter(params):any{
    
    let url = ApiModulosSettings.API_MODULO_NEWSLETTER_CREATE;
    
    return this.http.post(url,params)
    
  }
  /**
   * 
   * Retorna os produtos que tem o produto_categoria_id
   * 
   */
  getProdutosCategoria(produto_categoria_id):any{

    let url = ApiModulosSettings.API_MODULO_PRODUTOS.produtos_categoria+"/"+produto_categoria_id;
    
    return this.http.get(url)

  }
  /**
   * 
   * Filtra os módulos com base na posição
   * 
   */
  filterModulosByPosition(modulos,position):any{

    try{
      let out = [];

      if(modulos != null){
        modulos.forEach(element => {

          try{
            if(element.posicao == position){
              element.params = typeof(element.params) == "string" ? JSON.parse(element.params) : element.params;
              out.push(element);
            }
          }catch(e){
            //console.log(e.message);
          }  

        });
      }  

      return out;
    
    }catch(e){
      //console.log(e.message);
    }  

  }
  /**
   * 
   * Busca os módulos
   * 
   * 
   */
  getFotosBustour(params):any{
    
    let url = ApiModulosSettings.API_MODULO_FOTOS_BUSTOUR;
    
    return this.http.get(url,{params})
    
  }
  /**
   * 
   * Retorna os produtos que tem o produto_categoria_id
   * 
   */
  eventos():any{

    return{
      proximos: ():any => {
        
        let url = ApiModulosSettings.API_MODULO_EVENTOS.proximos_eventos;
    
        return this.http.get(url)
      
      }
    
    }
  
  }



}
