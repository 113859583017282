import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'pagination-custom',
  templateUrl: './pagination-custom.component.html',
  styleUrls: ['./pagination-custom.component.scss']
})
export class PaginationCustomComponent implements OnInit {

  @Input("pageSize") pageSize = null;
  @Input("pageNumber") pageNumber = null;
  @Input("total") total = null;
  @Output("changePage") changePage = new EventEmitter();
  public pages          = [];
  
  constructor() { }

  /**
   * 
   * Seta as páginas
   * 
   */
  setPages(){
    
    if(this.pageSize != null && this.pageNumber != null && this.total != null){
    
      this.pages = [];
      let numberPages = Math.ceil(this.total / this.pageSize);
    
      for (var index = 0; index < numberPages; index++) {
            
        this.pages.push({
          number: index+1,
          text: index+1
        });
            
      }
    
    }
    
  }
  /**
  * 
  * Acionado quando o usuário clica no link
  * 
  */
  onClick(number){
    
    if(number != this.pageNumber){
      
      this.changePage.emit(number);
    }
        
  }
  /**
  * 
  * Vai para a página anterior
  * 
  **/
  onPrev(){
    
    if(this.pageNumber > 1){
      this.onClick(this.pageNumber-1); 
    }
    
  }
  /**
  * 
  * Vai para a próxima página
  * 
  **/
  onNext(){
    
    if(!(this.pageNumber == (this.pages.length))){
      this.onClick(this.pageNumber+1); 
    }
    
  }
  /**
  * 
  * @param page 
  * 
  */
 displayLi(page){
      
    if(this.pageNumber == 1){
      return page.number == this.pageNumber || (page.number == (this.pageNumber+1)) || (page.number == (this.pageNumber+2));
    }else if((this.pages.length) == this.pageNumber){
      return page.number == this.pageNumber || (page.number == (this.pageNumber+1)) || (page.number == (this.pageNumber-1));
    }else{
      return page.number == this.pageNumber || (page.number == (this.pageNumber+1)) || (page.number == (this.pageNumber-1));
    }
  
  }
  /**
  * 
  * Inicializa as Funções
  * 
  */
  ngOnInit(){
   
    this.setPages();
    
  }
  ngOnChanges(changes: any): void {
        
    if(changes.total || changes.pageNumber || changes.pageSize){
      this.setPages();
    }
    
  }

}
