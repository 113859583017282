import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'combo-parcelas-carne',
  templateUrl: './combo-parcelas-carne.component.html',
  styleUrls: ['./combo-parcelas-carne.component.scss']
})
export class ComboParcelasCarneComponent implements OnInit {

  @Input("label") label = "Nº de Parcelas:"; 
  @Input("value") value = 2;
  @Input("max") max = 12;
  @Output("changeValue") change = new EventEmitter();
  public parcelas = [];
  
  constructor() { }
  


  /**
   * 
   *  Atualiza o valor selecionado
   * 
   * @param value
   * 
   */
  clickValue(value){

    this.change.emit(value);

  }
  /**
   * 
   * Populaas parcelas
   * 
   * 
   */
  populaParcelas(){

    this.parcelas = [];

    for (var index = 2; index <= this.max; index++) {
      
      this.parcelas.push(index);
      
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.populaParcelas();
  }

}
