import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clearCifrao'
})
export class ClearCifraoPipe implements PipeTransform {

  transform(valor: any, args?: any): any {
    
    return valor.replace(new RegExp(/[$]/g),"");
  
  }

}
