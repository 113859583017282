import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { MatAutocomplete } from '@angular/material/autocomplete';
import {map,startWith} from 'rxjs/operators';

@Component({
  selector: 'produto-form-search',
  templateUrl: './produto-form-search.component.html',
  styleUrls: ['./produto-form-search.component.scss']
})
export class ProdutoFormSearchComponent implements OnInit {

  public dataItem:any = {};
  @Input("produtos") produtos     = []; 
  @Input("categorias") categorias = [];
  @Input("template")     template = "one"; 
  @Output("change") _change       = new EventEmitter();
  public myControl                = new UntypedFormControl();
  public options:string[]         = [];
  public filteredOptions:Observable<string[]>;
  @ViewChild("auto") auto:MatAutocomplete;

  constructor() { }

  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      produto_categoria_id: ""
    }

  }
  /**
   * 
   * Filter
   * 
   * @param value 
   * 
   */
  private _filter(value: string): string[] {
    
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  
  }
  /***
   * 
   * Set Options
   * 
   */
  setOptions(id=null){

    this.options = [];

    for(let index = 0; index < this.produtos.length; index++) {
      
      if(id == null){
        this.options.push(this.produtos[index].nome); 
      }else if(id == this.produtos[index].produto_categoria_id){
        this.options.push(this.produtos[index].nome); 
      }  
      
    }
      

  }
  _changeInput(){

    this._change.emit(this.dataItem); 

  }
  _changeCategoria(){

    this.dataItem.nome = "";
    this._change.emit(this.dataItem); 

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

    this.setOptions();

    this.myControl.valueChanges.subscribe(data => {
      this._changeInput();
    });
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );

    this.initDataItem();
  }

}
