import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scoreAvaliacao'
})
export class ScoreAvaliacaoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    value = parseFloat(value).toFixed(1); 
    
    if(value <= 1){
      return "Ruim";
    }else if(value <= 2){
      return "Razoável!";
    }else if(value <= 3){
      return  "Bom!";
    }else if(value <= 4){
      return "Muito Bom!";
    }else{
      return "Excelente!";
    }

  }

}
