import { TranslateService } from './../services/translate.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform{

  constructor(
    private translate: TranslateService
  ){

  }
  /**
   * 
   * 
   * 
   * @param value 
   * @param args
   *  
   */
  transform(value:any,args?: any): any {
    
    return this.translate.getValue(value);

  }

}
