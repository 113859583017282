import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'evento-transporte-passageiros',
  templateUrl: './evento-transporte-passageiros.component.html',
  styleUrls: ['./evento-transporte-passageiros.component.scss']
})
export class EventoTransportePassageirosComponent implements OnInit {

  @Input("data") data                 = [];
  @Input("hoteis") hoteis             = [];
  @Output("updateData") updateData    = new EventEmitter();
  @Input("updateHoteis") updateHoteis = new EventEmitter();
  public passageirosHotel             = [];
  public passageiro                   = "";
  public locale = "pt";

  constructor(
    public app: AppService
  ){ 

    this.locale  = this.app.getLocale(); 

  }
  /**
   * 
   * Set passageiros
   * 
   */
  setPassageiros(){

    if(this.hoteis.length){
      
      this.passageirosHotel = [];

      for(let index = 0;index < this.hoteis.length;index++) {
        
        for(let i = 0;i < this.hoteis[index].passageiros.length;i++) {

          this.passageirosHotel.push(this.hoteis[index].passageiros[i]);

        }  
        
      }

    }

  }
  /**
   * 
   * Seta os dados
   * 
   */
  setData(){

    for(let index = 0; index < this.data.length; index++) {
      
      this.data[index].passageiros = [];

      for(let y = 0;y < this.data[index].quantidade;y++) {

        this.data[index].passageiros.push({
          nome: "",
          cpf: "",
          rg: "",
          tipo: "adt",
        });

      } 
      for(let y = 0;y < this.data[index].quantidade_chd;y++) {

        this.data[index].passageiros.push({
          nome: "",
          cpf: "",
          rg: "",
          tipo: "chd",
        });

      }
    }
    this.updateData.emit(this.data);

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

  }  
  /**
   * 
   * Change passageiro
   * 
   * @param passageiro 
   * 
   */
  _changePassageiro(data,passageiro){

    passageiro.nome = data.nome;
    passageiro.cpf  = data.cpf;
    passageiro.rg   = data.rg;

  }
  /**
   * 
   * Inicializa as Funções
   *  
   **/  
  ngOnInit(){
    this.onData();
    this.setData();
    this.setPassageiros();
  }
  /**
   * 
   * On changes
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any): void {
  

  }

}
