import { ConteudoService } from './services/conteudo.service';
import { PhotoswipeService } from './services/photoswipe.service';
import { AppService } from './services/app.service';
import { ModulosService } from './services/modulos.service';
import { DataService } from './services/data.service';
import { ModulosResolver } from './resolvers/modulos.resolver';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 
import { HomePageComponent } from './pages/home-page/home-page.component';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoComponent } from './components/modulos/logo/logo.component';
import { ModuloComponent } from './components/modulos/modulo/modulo.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BannerHomeComponent } from './components/banner-home/banner-home.component';
import { SobreComponent } from './components/modulos/sobre/sobre.component';
import { ContatoComponent } from './components/modulos/contato/contato.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { ClearUrlPipe } from './pipes/clear-url.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { PswpTemplateComponent } from './components/pswp-template/pswp-template.component'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ErrorServidorComponent } from './components/errors/error-servidor/error-servidor.component';
import { SiteDesabilitadoPageComponent } from './pages/site-desabilitado-page/site-desabilitado-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { EnderecoComponent } from './components/modulos/endereco/endereco.component';
import { BannerSliderComponent } from './components/modulos/banner-slider/banner-slider.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ContatoPageComponent } from './pages/contato-page/contato-page.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { AppLinksComponent } from './components/modulos/app-links/app-links.component';
import { NewsletterComponent } from './components/modulos/newsletter/newsletter.component';
import { SeloTripComponent } from './components/modulos/selo-trip/selo-trip.component';
import { TelefoneDddDirective } from './directives/telefone-ddd.directive';
import { MaskDirective } from './directives/mask.directive';
import { MapGoogleComponent } from './components/mapas/map-google/map-google.component';
import { ProdutosCategoriaComponent } from './components/modulos/produtos-categoria/produtos-categoria.component';
import { ProdutoTemplateOneComponent } from './components/produtos/produto-template-one/produto-template-one.component';
import { ProdutoFotoComponent } from './components/produtos/produto-foto/produto-foto.component';
import { ProdutoValorPipe } from './pipes/produto-valor.pipe';
import { ErrorCustomComponent } from './components/errors/error-custom/error-custom.component';
import { PaginationCustomComponent } from './components/pagination/pagination-custom/pagination-custom.component';
import { FooterContatoComponent } from './components/footer-contato/footer-contato.component';
import { SobrePageComponent } from './pages/sobre-page/sobre-page.component';
import { GaleriaOneComponent } from './components/galeria/galeria-one/galeria-one.component';
import { EventoDateFormatPipe } from './pipes/evento-date-format.pipe';
import { DateService } from './services/date.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ServicosOferecidosComponent } from './components/modulos/servicos-oferecidos/servicos-oferecidos.component';
import { RedeSocialComponent } from './components/modulos/rede-social/rede-social.component';
import { ProdutoListaCategoriasComponent } from './components/modulos/produto-lista-categorias/produto-lista-categorias.component';
import { ClientePage } from './pages/cliente-page/cliente-page.component';
import { ApiService } from './services/api.service';
import { ProdutoItemComponent } from './components/produtos/produto-item/produto-item.component';
import { CartService } from './services/cart.service';
import { UppercaseDirective } from './directives/uppercase.directive';
import { ErrorOneComponent } from './components/errors/error-one/error-one.component';
import { CalcValorTotalPipe } from './pipes/calc-valor-total.pipe';
import { CalcValorServicoPipe } from './pipes/calc-valor-servico.pipe';
import { TipoPaxsDescPipe } from './pipes/tipo-paxs-desc.pipe';
import { ComboParcelasCarneComponent } from './components/combos/combo-parcelas-carne/combo-parcelas-carne.component';
import { ComboParcelasCartaoComponent } from './components/combos/combo-parcelas-cartao/combo-parcelas-cartao.component';
import { ComboValidadeAnoComponent } from './components/combos/combo-validade-ano/combo-validade-ano.component';
import { ComboValidadeMesComponent } from './components/combos/combo-validade-mes/combo-validade-mes.component';
import { BtnConsultarReservaComponent } from './components/btns/btn-consultar-reserva/btn-consultar-reserva.component';
import { BtnVisualizarBoletoComponent } from './components/btns/btn-visualizar-boleto/btn-visualizar-boleto.component';
import { BtnVisualizarBoletoTaxaComponent } from './components/btns/btn-visualizar-boleto-taxa/btn-visualizar-boleto-taxa.component';
import { BtnVisualizarCarneComponent } from './components/btns/btn-visualizar-carne/btn-visualizar-carne.component';
import { BtnVoltarInicioComponent } from './components/btns/btn-voltar-inicio/btn-voltar-inicio.component';
import { BtnVisualizarVoucherComponent } from './components/btns/btn-visualizar-voucher/btn-visualizar-voucher.component';
import { IntegerDirective } from './directives/integer.directive';
import { MatModalInfoDialogComponent } from './components/modais/mat-modal-info-dialog/mat-modal-info-dialog.component';
import { EmptyPipe } from './pipes/empty.pipe';
import { ModalConfirmComponent } from './components/modais/modal-confirm/modal-confirm.component';
import { ModalInfoComponent } from './components/modais/modal-info/modal-info.component';
import { WhatsappComponent } from './components/modulos/whatsapp/whatsapp.component';
import { LinguagemSelectComponent } from './components/linguagem-select/linguagem-select.component';
import { MenuFooterComponent } from './components/menu-footer/menu-footer.component';
import { TripAdvisorComponent } from './components/modulos/trip-advisor/trip-advisor.component';
import { ContatosHomeComponent } from './components/contatos-home/contatos-home.component';
import { ProdutoDestaquesComponent } from './components/modulos/produto-destaques/produto-destaques.component';
import { DivisorTitleComponent } from './components/divisor-title/divisor-title.component';
import { DivisorTitleTwoComponent } from './components/divisor-title-two/divisor-title-two.component';
import { ProdutoItemBannerComponent } from './components/produtos/produto-item-banner/produto-item-banner.component';
import { DivisorFullComponent } from './components/divisor-full/divisor-full.component';
import { PasseiosPage } from './pages/passeios-page/passeios-page.component';
import { MapaGoogleComponent } from './components/mapa/mapa-google/mapa-google.component';
import { ProdutoItemValorComponent } from './components/produtos/produto-item-valor/produto-item-valor.component';
import { MaxPipe } from './pipes/max.pipe';
import { TrasladosDestaquesComponent } from './components/modulos/traslados-destaques/traslados-destaques.component';
import { StorageService } from './services/storage.service';
import { ServicoService } from './services/servico.service';
import { GetTarifaMinimaListaPipe } from './pipes/get-tarifa-minima-lista.pipe';
import { FormatValorManagerPipe } from './pipes/format-valor-manager.pipe';
import { ComboManagerMacroRegiaoComponent } from './components/combos/combo-manager-macro-regiao/combo-manager-macro-regiao.component';
import { ComboManagerHotelComponent } from './components/combos/combo-manager-hotel/combo-manager-hotel.component';
import { MatSelectSearchComponent } from './components/combos/mat-select-search/mat-select-search.component';
import { TipoPassageiroDescPipe } from './pipes/tipo-passageiro-desc.pipe';
import { ValidatorService } from './services/validator.service';
import { TipoServicoPipe } from './pipes/tipo-servico.pipe';
import { ClearCifraoPipe } from './pipes/clear-cifrao.pipe';
import { ServicoCategoriaPage } from './pages/servico-categoria-page/servico-categoria-page.component';
import { ProdutoCategoriaItemComponent } from './components/produtos/produto-categoria-item/produto-categoria-item.component';
import { DmBreadcrumbsComponent } from './components/breadcrumbs/dm-breadcrumbs/dm-breadcrumbs.component';
import { ProdutoCategoriaDestaqueComponent } from './components/modulos/produto-categoria-destaque/produto-categoria-destaque.component';
import { ComboManagerAeroportoComponent } from './components/combos/combo-manager-aeroporto/combo-manager-aeroporto.component';
import { ComboManagerCiaComponent } from './components/combos/combo-manager-cia/combo-manager-cia.component';
import { ComboCiaAereaComponent } from './components/combos/combo-cia-aerea/combo-cia-aerea.component';
import { ComboAeroportoComponent } from './components/combos/combo-aeroporto/combo-aeroporto.component';
import { PoliticaDeCancelamentoPage } from './pages/politica-de-cancelamento-page/politica-de-cancelamento-page.component';
import { PoliticaDePrivacidadePage} from './pages/politica-de-privacidade-page/politica-de-privacidade-page.component';
import { ParceirosComponent } from './components/modulos/parceiros/parceiros.component';
import { DestinosComponent } from './components/modulos/destinos/destinos.component';
import { DestinoServicosPage } from './pages/destinos/destino-servicos-page/destino-servicos-page.component';
import { GaleriaTwoComponent } from './components/galeria/galeria-two/galeria-two.component';
import { ProdutoFormContatoComponent } from './components/produtos/produto-form-contato/produto-form-contato.component';
import { MeiosPagamentoComponent } from './components/meios-pagamento/meios-pagamento.component';
import { ProdutoFormSearchComponent } from './components/produtos/produto-form-search/produto-form-search.component';
import { ProdutoVendaOfflineComponent } from './components/produtos/produto-venda-offline/produto-venda-offline.component';
import { ProdutoSlideShowComponent } from './components/produtos/produto-slide-show/produto-slide-show.component';
import { UnidadeItemComponent } from './components/unidades/unidade-item/unidade-item.component';
import { HorariosModulo } from './components/modulos/horarios-modulo/horarios-modulo.component';
import { FormasDePagamentoComponent } from './components/formas-de-pagamento/formas-de-pagamento.component';
import { ProdutoRegiaoDestaqueComponent } from './components/modulos/produto-regiao-destaque/produto-regiao-destaque.component';
import { ProdutoRegiaoItemComponent } from './components/produtos/produto-regiao-item/produto-regiao-item.component';
import { ProdutoFormSearchTwoComponent } from './components/produtos/produto-form-search-two/produto-form-search-two.component';
import { MenuTopoComponent } from './components/menu-topo/menu-topo.component';
import { ConsultarServicosComponent } from './components/servicos/consultar-servicos/consultar-servicos.component';
import { BannerSliderSlickComponent } from './components/modulos/banner-slider-slick/banner-slider-slick.component';
import { UnidadesComponent } from './components/modulos/unidades/unidades.component';
import { FaleConoscoComponent } from './components/modulos/fale-conosco/fale-conosco.component';
import { ProdutoCidadeFormComponent } from './forms/componente/produto/produto-cidade-form/produto-cidade-form.component';
import { GaleriaLightComponent } from './components/galeria/galeria-light/galeria-light.component';
import { FormatDuracaoPipe } from './pipes/format-duracao.pipe';
import { ServicoObservacoesFormatDirective } from './directives/servico-observacoes-format.directive';
import { BoxFormPesquisaComponent } from './components/box-form-pesquisa/box-form-pesquisa.component';
import { ProdutoAvaliacaoComponent } from './components/produtos/produto-avaliacao/produto-avaliacao.component';
import { ProdutoItemTwoComponent } from './components/produtos/produto-item-two/produto-item-two.component';
import { ProdutoNovidadeComponent } from './components/modulos/produto-novidade/produto-novidade.component';
import { ProdutoItemThreeComponent } from './components/produtos/produto-item-three/produto-item-three.component';
import { ProdutoItemCampeaoVendaComponent } from './components/produtos/produto-item-campeao-venda/produto-item-campeao-venda.component';
import { ProdutoDicaRoteiroComponent } from './components/modulos/produto-dica-roteiro/produto-dica-roteiro.component';
import { ProdutoAvaliacaoFormComponent } from './components/produtos/produto-avaliacao-form/produto-avaliacao-form.component';
import { ProdutoAvaliacaoListaComponent } from './components/produtos/produto-avaliacao-lista/produto-avaliacao-lista.component';
import { ScoreAvaliacaoPipe } from './pipes/score-avaliacao.pipe';
import { MesTextPipe } from './pipes/mes-text.pipe';
import { DestaqueBlogComponent } from './components/modulos/destaque-blog/destaque-blog.component';
import { EventoFormBuscaComponent } from './components/evento/evento-form-busca/evento-form-busca.component';
import { EventosDestaqueComponent } from './components/modulos/eventos-destaque/eventos-destaque.component';
import { EventoItemComponent } from './components/evento/evento-item/evento-item.component';
import { EventoPage } from './pages/eventos/evento-page/evento-page.component';
import { EventosPage } from './pages/eventos/eventos-page/eventos-page.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ServicosComponent } from './components/modulos/servicos/servicos.component';
import { TranslateService } from './services/translate.service';
import { TranslatePipe } from './pipes/translate.pipe';
import { HospedagemItemComponent } from './components/evento/hospedagem-item/hospedagem-item.component';
import { HotelModalDetalheComponent } from './components/evento/hotel-modal-detalhe/hotel-modal-detalhe.component';
import { TransporteItemComponent } from './components/evento/transporte-item/transporte-item.component';
import { PasseioItemComponent } from './components/evento/passeio-item/passeio-item.component';
import { HotelModalValoresComponent } from './components/evento/hotel-modal-valores/hotel-modal-valores.component';
import { TransporteModalDetalheComponent } from './components/evento/transporte-modal-detalhe/transporte-modal-detalhe.component';
import { TransporteModalValoresComponent } from './components/evento/transporte-modal-valores/transporte-modal-valores.component';
import { PasseioModalDetalheComponent } from './components/evento/passeio-modal-detalhe/passeio-modal-detalhe.component';
import { PasseioModalValoresComponent } from './components/evento/passeio-modal-valores/passeio-modal-valores.component';
import { EventoFormSearchComponent } from './components/evento/evento-form-search/evento-form-search.component';
import { SelectLgComponent } from './components/select-lg/select-lg.component';
import { TranslateParamsPipe } from './pipes/translate-params.pipe';
import { EventoTwoPage } from './pages/eventos/evento-two-page/evento-two-page.component';
import { HospedagemItemTwo } from './components/evento/hospedagem-item-two/hospedagem-item-two.component';
import { TransporteItemTwoComponent } from './components/evento/transporte-item-two/transporte-item-two.component';
import { PasseioItemTwoComponent } from './components/evento/passeio-item-two/passeio-item-two.component';
import { DeviceDetectorModule } from 'ngx-device-detector'; 
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { HoraVooTextPipe } from './pipes/hora-voo-text.pipe';
import { ModalTermosComponent } from './components/modais/modal-termos/modal-termos.component';
import { EventoTransportePassageirosComponent } from './components/evento/evento-transporte-passageiros/evento-transporte-passageiros.component';
import { TipoPassageiroTextPipe } from './pipes/tipo-passageiro-text.pipe';
import { EventoBannerComponent } from './components/evento/evento-banner/evento-banner.component';
import { EventoHotelPassageirosComponent } from './components/evento/evento-hotel-passageiros/evento-hotel-passageiros.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';

registerLocaleData(localePt, 'pt-BR');  

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        MenuComponent,
        HeaderComponent,
        LogoComponent,
        ModuloComponent,
        LoaderComponent,
        BannerHomeComponent,
        SobreComponent,
        ContatoComponent,
        CopyrightComponent,
        ClearUrlPipe,
        FilterPipe,
        PswpTemplateComponent,
        ErrorServidorComponent,
        SiteDesabilitadoPageComponent,
        FooterComponent,
        EnderecoComponent,
        BannerSliderComponent,
        SafeHtmlPipe,
        ContatoPageComponent,
        BreadcrumbsComponent,
        SobrePageComponent,
        AppLinksComponent,
        NewsletterComponent,
        SeloTripComponent,
        TelefoneDddDirective,
        MaskDirective,
        MapGoogleComponent,
        ProdutosCategoriaComponent,
        ProdutoTemplateOneComponent,
        ProdutoFotoComponent,
        ProdutoValorPipe,
        ErrorCustomComponent,
        PaginationCustomComponent,
        FooterContatoComponent,
        GaleriaOneComponent,
        EventoDateFormatPipe,
        ServicosOferecidosComponent,
        RedeSocialComponent,
        ProdutoListaCategoriasComponent,
        ClientePage,
        ProdutoItemComponent,
        UppercaseDirective,
        ErrorOneComponent,
        CalcValorTotalPipe,
        CalcValorServicoPipe,
        TipoPaxsDescPipe,
        ComboParcelasCarneComponent,
        ComboParcelasCartaoComponent,
        ComboValidadeAnoComponent,
        ComboValidadeMesComponent,
        BtnConsultarReservaComponent,
        BtnVisualizarBoletoComponent,
        BtnVisualizarBoletoTaxaComponent,
        BtnVisualizarCarneComponent,
        BtnVoltarInicioComponent,
        BtnVisualizarVoucherComponent,
        IntegerDirective,
        MatModalInfoDialogComponent,
        EmptyPipe,
        ModalConfirmComponent,
        ModalInfoComponent,
        WhatsappComponent,
        LinguagemSelectComponent,
        MenuFooterComponent,
        TripAdvisorComponent,
        ContatosHomeComponent,
        ProdutoDestaquesComponent,
        DivisorTitleComponent,
        DivisorTitleTwoComponent,
        ProdutoItemBannerComponent,
        DivisorFullComponent,
        PasseiosPage,
        MapaGoogleComponent,
        ProdutoItemValorComponent,
        MaxPipe,
        TrasladosDestaquesComponent,
        GetTarifaMinimaListaPipe,
        FormatValorManagerPipe,
        ComboManagerMacroRegiaoComponent,
        ComboManagerHotelComponent,
        MatSelectSearchComponent,
        TipoPassageiroDescPipe,
        TipoServicoPipe,
        ClearCifraoPipe,
        ServicoCategoriaPage,
        ProdutoCategoriaItemComponent,
        DmBreadcrumbsComponent,
        ProdutoCategoriaDestaqueComponent,
        ComboManagerAeroportoComponent,
        ComboManagerCiaComponent,
        ComboCiaAereaComponent,
        ComboAeroportoComponent,
        PoliticaDeCancelamentoPage,
        PoliticaDePrivacidadePage,
        ParceirosComponent,
        DestinosComponent,
        DestinoServicosPage,
        GaleriaTwoComponent,
        ProdutoFormContatoComponent,
        MeiosPagamentoComponent,
        ProdutoFormSearchComponent,
        ProdutoVendaOfflineComponent,
        ProdutoSlideShowComponent,
        UnidadeItemComponent,
        HorariosModulo,
        FormasDePagamentoComponent,
        ProdutoRegiaoDestaqueComponent,
        ProdutoRegiaoItemComponent,
        ProdutoFormSearchTwoComponent,
        MenuTopoComponent,
        ConsultarServicosComponent,
        BannerSliderSlickComponent,
        UnidadesComponent,
        FaleConoscoComponent,
        ProdutoCidadeFormComponent,
        GaleriaLightComponent,
        FormatDuracaoPipe,
        ServicoObservacoesFormatDirective,
        BoxFormPesquisaComponent,
        ProdutoAvaliacaoComponent,
        ProdutoItemTwoComponent,
        ProdutoNovidadeComponent,
        ProdutoItemThreeComponent,
        ProdutoItemCampeaoVendaComponent,
        ProdutoDicaRoteiroComponent,
        ProdutoAvaliacaoFormComponent,
        ProdutoAvaliacaoListaComponent,
        ScoreAvaliacaoPipe,
        MesTextPipe,
        DestaqueBlogComponent,
        EventoFormBuscaComponent,
        EventosDestaqueComponent,
        EventoItemComponent,
        EventoPage,
        EventosPage,
        PhoneMaskDirective,
        ServicosComponent,
        TranslatePipe,
        HospedagemItemComponent,
        HotelModalDetalheComponent,
        TransporteItemComponent,
        PasseioItemComponent,
        HotelModalValoresComponent,
        TransporteModalDetalheComponent,
        TransporteModalValoresComponent,
        PasseioModalDetalheComponent,
        PasseioModalValoresComponent,
        EventoFormSearchComponent,
        SelectLgComponent,
        TranslateParamsPipe,
        EventoTwoPage,
        HospedagemItemTwo,
        TransporteItemTwoComponent,
        PasseioItemTwoComponent,
        HoraVooTextPipe,
        ModalTermosComponent,
        EventoTransportePassageirosComponent,
        TipoPassageiroTextPipe,
        EventoBannerComponent,
        EventoHotelPassageirosComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        MatIconModule,
        DeviceDetectorModule.forRoot(),
        MatAutocompleteModule
    ],
    providers: [
        ModulosResolver,
        DataService,
        ModulosService,
        AppService,
        PhotoswipeService,
        ConteudoService,
        DateService,
        ApiService,
        CartService,
        DataService,
        StorageService,
        ServicoService,
        ValidatorService,
        TranslateService,
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
