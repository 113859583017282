import { ModulosResolver } from './resolvers/modulos.resolver';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContatoPageComponent } from './pages/contato-page/contato-page.component';
import { SobrePageComponent } from './pages/sobre-page/sobre-page.component';
import { EventosPage } from './pages/eventos/eventos-page/eventos-page.component';
import { EventoTwoPage } from './pages/eventos/evento-two-page/evento-two-page.component';

const routes: Routes = [{
    path: '', 
    component: HomePageComponent,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'quem-somos',
    component: SobrePageComponent,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'contato',
    component: ContatoPageComponent,
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'eventos',
    component: EventosPage, 
    resolve:{
      modulos: ModulosResolver
    }
  },{
    path: 'eventos/:apelido',
    component: EventoTwoPage, 
    resolve:{
      modulos: ModulosResolver 
    }
  },{
    path: '**',
    pathMatch: 'full', 
    redirectTo: ''
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
