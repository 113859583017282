import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoServico'
})
export class TipoServicoPipe implements PipeTransform {

  transform(tipo: any, args?: any): any {
    
    return tipo === 1 || tipo === "1" ? "Regular" : "Privativo";
  
  }

}
