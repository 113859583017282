import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-app-links',
  templateUrl: './app-links.component.html',
  styleUrls: ['./app-links.component.scss']
})
export class AppLinksComponent implements OnInit {

  @Input("data") data;
  @Input("classCustom") classCustom = "";
  @Input("template") template;

  constructor() { }

  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {
  }

}
