import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-trip-advisor',
  templateUrl: './trip-advisor.component.html',
  styleUrls: ['./trip-advisor.component.scss']
})
export class TripAdvisorComponent implements OnInit {

  @Input("data") data:any       = null;
  @Input("template") template   = null;

  constructor() { } 

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
  
  }

}
