import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'produto-form-contato',
  templateUrl: './produto-form-contato.component.html',
  styleUrls: ['./produto-form-contato.component.scss']
})
export class ProdutoFormContatoComponent implements OnInit {

  @Input("servico") servico = null;
  public dataItem:any       = {};
  public loader             = false;
  public messageLoader      = "Enviando seu contato. Aguarde!"; 
  public message            = {
    data: "",
    class: "",
    show: false
  };

  constructor(
    private api: ApiService,
    private app: AppService
  ){}

  /***
   * 
   * Envia a mensagem por email
   * 
   * 
   */
  send(){

    try{

      this.loader = true;

      this.api.produto().send_contato(this.dataItem).subscribe(response => {

        this.loader = false;

        switch(response.status){
          case 1:
            this.message.data  = "Seus dados foram enviados com sucesso.";
            this.message.show  = true;
            this.message.class = "alert alert-success";
            this.initDataItem();
          break;
          case 0:
            let errors = this.app.getErrors([response.data]);
            
            this.message.data  = errors;
            this.message.show  = true;
            this.message.class = "alert alert-warning";
          break;
          default:
            this.message.data  = "Houve um erro interno. Tente mais tarde ou informe nossa equipe.";
            this.message.show  = true;
            this.message.class = "alert alert-danger";
          break;
        }


      },(response) => {

        this.loader        = false;
        let error          = this.app.formatError(response);
        this.message.data  = error.message;
        this.message.show  = true;
        this.message.class = "alert alert-danger";

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Incializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      celular: "",
      mensagem: "",
      servico: this.servico 
    }

  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.initDataItem();
  }

}
