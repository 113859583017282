import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-banner-home',
  templateUrl: './banner-home.component.html',
  styleUrls: ['./banner-home.component.scss']
})
export class BannerHomeComponent implements OnInit {

  public bg        = "/images/modulos/banner-home/bg.jpg";
  public person    = "/images/modulos/banner-home/person.png";
  public luz       = "/images/modulos/banner-home/luz.png";
  public desk      = "/images/modulos/banner-home/desk.png";
  public pen       = "/images/modulos/banner-home/pen.png";
  public mug       = "/images/modulos/banner-home/mug.png";
  public bgCafe    = "/images/modulos/banner-home/bg-cafe.png";
  public bgCafeTwo = "/images/modulos/banner-home/bg-cafe-two.png";
  public show      = false;

  constructor(){}

  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){


  }

}
