import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from './../../../services/app.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'modulo-error-servidor',
  templateUrl: './error-servidor.component.html',
  styleUrls: ['./error-servidor.component.scss']
})
export class ErrorServidorComponent implements OnInit {

  public name = AppService.name;

  constructor(public router: Router) { }

  reload(){
    
    document.location.assign(this.router.url);

  }
  ngOnInit() {
  }

}
