import { Component, OnInit, Input, ViewChild, ElementRef, ComponentFactoryResolver, AfterViewInit, NgZone } from '@angular/core';
import { ModulosService } from '../../../services/modulos.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'modulo-position',
  templateUrl: './modulo.component.html',
  styleUrls: ['./modulo.component.scss']
})
export class ModuloComponent implements OnInit,AfterViewInit {
  
  @Input("position") position;
  @Input("template") template = null;
  @Input("classCustom") classCustom = "";
  @ViewChild("content") content:ElementRef;
  public modulos:any = [];
  @Input("onInit") onInit:any = true;

  constructor(
    private ModulosService: ModulosService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private zone: NgZone,
    private data: DataService 
  ){
    
  }
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){

    this.ModulosService.updateModulosEmitter.subscribe(data =>{
      
      this.modulos = this.ModulosService.filterModulosByPosition(data,this.position);

    });
    this.ModulosService.getModulosEmitter.emit()
    

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    
    if(this.onInit){
      this.onData(); 
    }  

  }
  ngAfterViewInit(){
    
    if(this.onInit == false || this.onInit == "false"){
      this.onData(); 
    }

  }


}
