import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-item-three',
  templateUrl: './produto-item-three.component.html',
  styleUrls: ['./produto-item-three.component.scss']
})
export class ProdutoItemThreeComponent implements OnInit {

  @Input("data") produto:any            = {};
  @Input("categoria") categoria:any     = null;
  @Input("classCustom") classCustom:any = "";
  @Input("maxText") maxText:any         = null;
  @Input("showValor") showValor:any     = false;
  @Input("tipoTextSaibaMais") tipoTextSaibaMais = "text";
  
  public link = null;

  constructor(){ 

  }
  /**
   * 
   * Seta o link para o detalhe do produto
   * 
   */
  setLink(){

    if(this.categoria ==  null){
      this.link = "/servicos/"+this.produto.categoria_apelido+"/"+this.produto.apelido;
    }else{
      this.link = "/servicos/"+this.categoria.apelido+"/"+this.produto.apelido;
    }  

  }
  /***
   * 
   * Seta
   * 
   */
  setDescricaoListagem(){

    if(this.maxText != null){

      let max   = this.maxText;
      let limit:any = parseInt(max);

      if(this.produto.descricao_listagem.length > limit){
        this.produto.descricao_listagem = this.produto.descricao_listagem.substring(0,limit)+"...";
      }
      
    }  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLink();
    this.setDescricaoListagem();
  }

}
