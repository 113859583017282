import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'modulo-fale-conosco', 
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss']
})
export class FaleConoscoComponent implements OnInit {

  @Input("data") data:any = {};
  public dataItem:any        = {};
  public loader           = false;
  public subscribeMessage = "";

  constructor(
    private api: ApiService,
    private app: AppService
  ){ }

  /**
   * 
   * Send data
   * 
   */
  send(){

    try{ 

      this.loader           = true;
      this.subscribeMessage = "";

      this.api.contato().fale_conosco(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          this.initDataItem();
          this.subscribeMessage = "Mensagem enviada com sucesso!";
        }else{
          this.subscribeMessage = response.data;
        }

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);

        this.subscribeMessage = error.message;

      });

    }catch(e){

      this.loader = false;
      

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      mensagem: ""
    }

  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.initDataItem();
  }

}
