import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoPassageiroText'
})
export class TipoPassageiroTextPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    let desc = "";

    switch(value){
      case 'adt':
        desc = "Adulto";
      break;
      case 'chd':
        desc = "Criança";
      break;
    }

    return desc;

  }

}
