import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { MatSelect } from '@angular/material/select';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'combo-manager-macro-regiao',
  templateUrl: './combo-manager-macro-regiao.component.html',
  styleUrls: ['./combo-manager-macro-regiao.component.scss']
})
export class ComboManagerMacroRegiaoComponent implements OnInit {
  
  @Input("label") label = "Escolha a Região:";
  @Input("value") value = null;
  @Input("text") text   = null;
  @ViewChild("select") select:MatSelect;
  @Output("changeValue") changeValue = new EventEmitter();
  public loader         = false;
  public response       = {
    data: [],
    status: null,
    message: ""
  };

  constructor(
    private api: ApiService,
    private app: AppService
  ){ }

  /**
   * 
   * Retorna os dados para o ComboBox
   * 
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.manage().regioes().subscribe(response => {

        this.loader = false;
                
        if(response.status == 1){
          this.response = response;
          setTimeout(() => {
            this.select.open();
          },100);
        }else{

          this.response = {
            status: 500,
            data: [],
            message: response.data
          }

        }  

      },(response) => {

        this.loader   = false;
        let error     = this.app.formatError(response);

        this.response = {
          status: 500,
          data: [],
          message: error.message
        }

      });

    }catch(e){

      this.loader = false;

    }

  }
  /***
   * 
   * Verifica a mudança
   * 
   */
  _change(){

    let item = this._getDataItem();
    
    if(item != null){
      this.changeValue.emit(item);
    }

  }
  /**
   * 
   * Get dataItem
   * 
   */
  _getDataItem(){

    let data = null;

    for(let index = 0; index < this.response.data.length; index++) {

      if(this.value == this.response.data[index]["idMacroRegiao"]){

        data = this.response.data[index];
        break;

      }
      
    }

    return data;

  }
  /**
  * 
  * 
  * 
  */
  _getData(){
    
    if(this.response.status != 1){
      this.getData();
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

   

  }

}
