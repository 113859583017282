import { Component, OnInit, EventEmitter, ViewChild, ElementRef, OnDestroy, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';
import { DateService } from '../../../services/date.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalTermosComponent } from '../../../components/modais/modal-termos/modal-termos.component';
import { ValidatorService } from '../../../services/validator.service';

declare var $:any;

@Component({
  selector: 'evento-two-page',
  templateUrl: './evento-two-page.component.html',
  styleUrls: ['./evento-two-page.component.scss']
})
export class EventoTwoPage implements OnInit,OnDestroy,OnChanges {

  public clearInput           = new EventEmitter();
  public clearInputTransporte = new EventEmitter();
  public apelido              = null;
  public loader               = false;
  public itensBreadcrumbs     = [];
  public response      = {
    data: "",
    status: null
  }
  public evento       = null;
  public content      = 1;
  public hospedagens  = null;
  public transportes  = null;
  public passeios     = null;
  public dataItem:any = null;
  public locale       = "pt";
  public successSend  = false;
  public isSelecionado = false;
  public total          = 0;
  public totalTraslados = 0;
  public totalPasseios  = 0;
  @ViewChild("dataIn") dataIn:ElementRef;
  @ViewChild("dataOut") dataOut:ElementRef;
  public clearAll              = new EventEmitter();
  public dataInicio            = null;
  public dataFim               = null;
  public parcelas              = [];
  public bandeiras             = [];
  public banners               = [];
  public passageiroTitular:any = null;
  public _updateHoteis         = new EventEmitter(); 

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private app: AppService,
    private date: DateService,
    private dialog: MatDialog,
    private validator: ValidatorService
  ){ 

    this.apelido = typeof(this.route.params["_value"].apelido) != "undefined" ? this.route.params["_value"].apelido : null;
    this.locale  = this.app.getLocale();
      
  }
  
  /**
  * 
  * Retorna os dados do produto baseado no apelido
  * 
  * 
  **/
  getData(){

    try{

      this.loader           = true;
      this.itensBreadcrumbs = [];

      this.api.evento().evento(this.apelido).subscribe(response => {

        if(response.status == 1){

          this.evento = response.data.evento;
          
          if(response.data.evento == null){
            this.router.navigateByUrl("/");
          }
          
          this.hospedagens = response.data.hospedagens;
          this.transportes = response.data.transportes; 
          this.passeios    = response.data.passeios;
          this.banners     = response.data.banners;   

          this.setParcelas();
          this.app.setMetaDados(this.evento.meta_title,this.evento.meta_description);
          this.app.toTop(); 
          this.setItensBreadcrumbs();
          this.initDataItem();  
                                      
        }else{  
          this.response = response;
        }

        setTimeout(() => {
          this.loader = false;
        },10);
        setTimeout(() => {
          this.configDates();
        },500);

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);

        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader = false;

    }

  }
  /***
   * 
   * Seta os breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Eventos",
      routerLink: "/eventos"
    });
    this.itensBreadcrumbs.push({
      text: this.evento.nome,
      routerLink: "" 
    });

  }
  /**
   * 
   * Seta content
   * 
   */
  setContent(value,id=null){

    this.content = value == this.content ? null : value;

    setTimeout(() => {

      if(id != null){ 
        //this.app.toSection(id);
      }

    },200);

  }
  /**
   * 
   * Next
   * 
   */
  next(){ 
    
    try{

      this.content++;

      if(this.content == 1 && this.evento.incluir_hospedagem == false){
        this.content++;
      }
      if(this.content == 2 && this.evento.incluir_transporte == false){
        this.content++;
      }
      if(this.content == 3 && this.evento.incluir_passeio == false){
        this.content++;
      }
      setTimeout(() => {
        this.app.toSection("#page-content");   
      },500);
    
    }catch(e){
      
    }

  }
  /**
   * 
   * Prev
   * 
   */
  prev(){

    if(this.content > 1){

      this.content--;

      if(this.content == 3 && this.evento.incluir_passeio == false){
        this.content--;
      }
      if(this.content == 2 && this.evento.incluir_transporte == false){
        this.content--;
      }      
      
      this.app.toSection("#page-content"); 

    }

  }
  getIndexHotel(id){

    let index = null;

    for(let i = 0;i < this.dataItem.hoteis.length;i++) {
      
      if(this.dataItem.hoteis[i].id == id){

        index = i;  
        break;

      }
      
    }

    return index;

  }
  /**
   * 
   * Change Hotel
   * 
   */
  changeHotel(data){

    if(!data.deletar){

      let index = this.getIndexHotel(data.hotel.id);

      if(index == null){
        
        this.dataItem.hoteis.push({
          id: data.hotel.id,
          nome: data.hotel.nome,
          categoria: data.hotel.categoria,
          quarto: data.quarto,
          quantidade: data.quantidade,
          numero_pessoas: data.quarto.numero_pessoas
        });

      }else{

        this.dataItem.hoteis[index] = {
          id: data.hotel.id,
          nome: data.hotel.nome, 
          categoria: data.hotel.categoria,
          quarto: data.quarto,
          quantidade: data.quantidade,
          quantidade_chd: data.quantidade_chd
        }

      }  

    }else{

      let index = this.getIndexHotel(data.hotel.id);

      if(index != null){

        this.dataItem.hoteis.splice(index,1);

      }

    }
    this.calcularTotal();
    this.app.emitChangeHoteis.emit(this.dataItem.hoteis);

  }
  /**
   * 
   * Calcular totais
   * 
   */
  calcularTotal(){

    let total = 0;

    for(let index = 0; index < this.dataItem.hoteis.length; index++) {
      
      total += this.dataItem.dias*(this.dataItem.hoteis[index].quarto.valor); 
            
    }
    this.total           = total; 
    this.dataItem.total  = this.totalPasseios+this.totalTraslados+this.total; 


  }
  /**
   * 
   * Retorna os passeios
   * 
   * @param id 
   * 
   */
  getIndexPasseio(id){

    let index = null;

    for(let i = 0;i < this.dataItem.passeios.length;i++) {
      
      if(this.dataItem.passeios[i].id == id){

        index = i;  
        break;

      }
      
    }

    return index;

  }
  /**
   * 
   * Change Hotel
   * 
   */
  changePasseios(data){

    let index = this.getIndexPasseio(data.passeio.id);

    if(!data.deletar){

      if(index == null){

        this.dataItem.passeios.push({
          id: data.passeio.id,
          nome: data.passeio.nome, 
          valor: data.passeio.valor,
          quantidade: data.quantidade 
        });

      }else{

        this.dataItem.passeios[index] = {
          id: data.passeio.id,
          nome: data.passeio.nome, 
          valor: data.passeio.valor,
          quantidade: data.quantidade 
        };

      }
            
    }else{

      this.dataItem.passeios.splice(index,1);

    }
    this.calcularTotalPasseios();
  
  }
  /**
   * 
   * Calcular total passeios
   * 
   * @param id 
   * 
   */
  calcularTotalPasseios(){

    let total = 0;

    for(let index = 0;index < this.dataItem.passeios.length;index++) {
      
      total += this.dataItem.passeios[index].valor*this.dataItem.passeios[index].quantidade; 
      
    }

    this.totalPasseios   = total; 
    this.dataItem.total  = this.totalPasseios+this.totalTraslados+this.total; 

  }
  /**
   * 
   * @param id 
   * 
   */
  getIndexTransporte(id){

    let index = null;

    for(let i = 0;i < this.dataItem.transportes.length;i++) {
      
      if(this.dataItem.transportes[i].id == id){

        index = i;  
        break;

      }
      
    }

    return index;

  }
  /**
   * 
   * Change transportes
   * 
   */
  changeTransporte(data){
    
    let index = this.getIndexTransporte(data.transporte.id);
    
    if(!data.deletar){
      
      if(index == null){

        this.dataItem.transportes.push({
          id: data.transporte.id,
          titulo: data.transporte.titulo,
          transporte_tipo_id: data.transporte.transporte_tipo_id,
          descricao: data.transporte.descricao,
          voo_data: data.voo_data,
          voo_numero: data.voo_numero,
          voo_hora: data.voo_hora,
          voo_data_out: data.voo_data_out,
          voo_numero_out: data.voo_numero_out,
          voo_hora_out: data.voo_hora_out,
          valor: data.transporte.valor,
          valor_chd: data.transporte.valor_chd, 
          quantidade: data.quantidade,
          quantidade_chd: data.quantidade_chd,
          tipo: data.transporte.tipo,
          hotel: data.hotel, 
          utilizar_data_fixa: data.utilizar_data_fixa,
          data_fixa: data.data_fixa,
          data_fixa_out: data.data_fixa_out,
          aeroporto_id: data.aeroporto_id,
          aeroporto_out_id: data.aeroporto_out_id
        });

      }else{
        
        this.dataItem.transportes[index] = {
          id: data.transporte.id,
          titulo: data.transporte.titulo,
          transporte_tipo_id: data.transporte.transporte_tipo_id,
          descricao: data.transporte.descricao,
          voo_data: data.voo_data,
          voo_numero: data.voo_numero,
          voo_hora: data.voo_hora,
          voo_data_out: data.voo_data_out,
          voo_numero_out: data.voo_numero_out,
          voo_hora_out: data.voo_hora_out,
          valor: data.transporte.valor,
          valor_chd: data.transporte.valor_chd, 
          quantidade: data.quantidade,
          quantidade_chd: data.quantidade_chd,
          tipo: data.transporte.tipo,
          hotel: data.hotel,
          utilizar_data_fixa: data.utilizar_data_fixa,
          data_fixa: data.data_fixa,
          data_fixa_out: data.data_fixa_out,
          aeroporto_id: data.aeroporto_id,
          aeroporto_out_id: data.aeroporto_out_id
        };

      }
    
    }else{

      let index = this.getIndexTransporte(data.transporte.id);

      if(index != null){ 

        this.dataItem.transportes.splice(index,1);

      }
  
    }
    this.calcularTotalTraslados();
    

  }
  /**
   * 
   * Calcular total dos traslados
   * 
   */
  calcularTotalTraslados(){

    let total = 0;

    for (let index = 0; index < this.dataItem.transportes.length; index++) {
    
      total += (this.dataItem.transportes[index].valor*this.dataItem.transportes[index].quantidade)+(this.dataItem.transportes[index].valor_chd*this.dataItem.transportes[index].quantidade_chd);
      
    }

    this.totalTraslados = total;

    this.dataItem.total  = this.totalPasseios+this.totalTraslados+this.total; 
    
  }
  /**
   * 
   * Open Termos
   * 
   */
  openTermos(){

    this.dialog.open(ModalTermosComponent,{
      width: "500px",
      height: "100%",
      data:{
        termos: this.evento.termo_condicao 
      }
    });

  }
  /**
   * 
   * Clique termos
   * 
   */
  _clickTermos(){

    this.dataItem.termo_aceite = this.dataItem.termo_aceite == true ? false : true;

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      cpf: "",
      passaporte: "",
      cartao_nome: "",
      cartao_numero: "",
      cartao_validade: "", 
      cartao_codigo_seguranca: "",
      cartao_parcelas: 1,
      hotel_id: null,
      hotel: null,
      quartos: null,
      hoteis: [],
      transportes: [],
      passeios: [],
      evento_id: this.evento.id,
      dias: this.evento.min_diarias,  
      data_in: this.dataItem == null ? null : this.dataItem.data_in,
      data_out: this.dataItem == null  ? null : this.dataItem.data_out, 
      total: 0,
      tipo_pagamento: 1,
      termo_aceite: false
    }
    this.content        = 0;
    this.next(); 
    this.total          = 0;
    this.totalPasseios  = 0;
    this.totalTraslados = 0;

    this.clearAll.emit(); 

  }
  /**
   * 
   * Enviar
   * 
   */
  enviar(){

    try{

      let validationTransportes = "";
      let validationHoteis      = "";

      this.validator.clear();

      if(this.dataItem.transportes.length > 0){

        for(let index = 0; index < this.dataItem.transportes.length; index++) {

          if(typeof(this.dataItem.transportes[index].passageiros) != "undefined"){ 
            for(let y = 0;y < this.dataItem.transportes[index].passageiros.length;y++) {

              this.validator.isEmpty(this.dataItem.transportes[index].passageiros[y].nome,'nome-'+index+'-'+y,this.locale == 'pt' ? "- Informe o Nome (Passageiro "+(y+1)+")." : "- Inform the Name (Passenger "+(y+1)+").");
              if(this.locale == "pt"){
                this.validator.isEmpty(this.dataItem.transportes[index].passageiros[y].cpf,'cpf-'+index+'-'+y,"- Informe o CPF (Passageiro "+(y+1)+").");
                this.validator.validCpf(this.dataItem.transportes[index].passageiros[y].cpf,'cpf-'+index+'-'+y,"- O CPF é inválido (Passageiro "+(y+1)+").");
                this.validator.isEmpty(this.dataItem.transportes[index].passageiros[y].rg,'rg-'+index+'-'+y,"- Informe o RG (Passageiro "+(y+1)+").");
              }else{
                this.validator.isEmpty(this.dataItem.transportes[index].passageiros[y].passaporte,'cpf-'+index+'-'+y,"- Inform the Passaport (Passenger "+(y+1)+").");
              }

            }
          }
          if(!this.validator.passes()){   
            validationTransportes += "<h5>"+this.dataItem.transportes[index].titulo+"</h5>"; 
            validationTransportes += this.validator.getMessagesHtml();
          }  
          
        }

      } 
      if(this.dataItem.hoteis.length > 0){

        let statusValidation = false;

        for(let index = 0; index < this.dataItem.hoteis.length; index++) {

          statusValidation = false;

          for(let y = 0;y < this.dataItem.hoteis[index].passageiros.length;y++) {
            
            this.validator.isEmpty(this.dataItem.hoteis[index].passageiros[y].nome,'nome-'+index+'-'+y,this.locale == 'pt' ? "- Informe o Nome." : '- Inform the Name.');
            if(this.locale == "pt"){
              this.validator.isEmpty(this.dataItem.hoteis[index].passageiros[y].cpf,'cpf-'+index+'-'+y,"- Informe o CPF.");
              this.validator.validCpf(this.dataItem.hoteis[index].passageiros[y].cpf,'cpf-'+index+'-'+y,"- CPF inválido.");
              this.validator.isEmpty(this.dataItem.hoteis[index].passageiros[y].rg,'rg-'+index+'-'+y,"- Informe o RG.");
            }else{
              this.validator.isEmpty(this.dataItem.hoteis[index].passageiros[y].passaporte,'passaport-'+index+'-'+y,"- Inform the Passaport.");
            }
            if(this.dataItem.hoteis[index].passageiros[y].titular){
              this.validator.isEmpty(this.dataItem.hoteis[index].passageiros[y].telefone,'telefone-'+index+'-'+y,this.locale == 'pt' ? "- Informe o Telefone." : "- Inform the Phone.");
              this.validator.isEmpty(this.dataItem.hoteis[index].passageiros[y].email,'email-'+index+'-'+y,this.locale == 'pt' ? "- Informe o E-mail." : '- Inform the E-mail.');
              this.validator.isEmail(this.dataItem.hoteis[index].passageiros[y].email,'email-'+index+'-'+y,this.locale == 'pt' ? "- Informe um E-mail válido." : '- E-mail invalid.');
              this.validator.isEmpty(this.dataItem.hoteis[index].passageiros[y].data_nascimento,'nascimento-'+index+'-'+y,this.locale == 'pt' ? "- Informe a Data de Nascimento." : '- Inform the Birthday.');
            }   
            if(!this.validator.passes()){ 
              if(!statusValidation){
                validationHoteis += "<h5>"+this.dataItem.hoteis[index].nome+"</h5>"; 
              }
              validationHoteis += "<h6>Passageiro "+(y+1)+"</h6>";
              validationHoteis += this.validator.getMessagesHtml();
              statusValidation = true;
              this.validator.clear();
            }
          } 

        } 
         
      }
      
      let errors = "";

      if(validationTransportes.length > 0){

        errors += validationTransportes;

      }
      if(validationHoteis.length > 0){

        errors += validationHoteis;

      }
      if(errors.length > 0){ 
        this.app.info(errors,"danger");
        return false;
      }

      this.loader      = true;
      this.successSend = false;

      this.api.pedido().finalizar_pedido(this.dataItem).subscribe(response => {

        this.loader = false;
        
        if(response.status == 1){

          this.successSend = true;  

          if(this.locale == "pt"){

            this.app.info("Muito obrigado por sua compra conosco. Assim que sua reserva for processada, receberá instruções para o pagamento de acordo com a sua escolha.");

          }else if(this.locale == "es"){

            this.app.info("Muchas gracias por su compra con nosotros. Una vez procesada su reserva, recibirá las instrucciones de pago según su elección.");
 
          }else{ 

            this.app.info("Thank you very much for your purchase with us. Once your booking has been processed, you will receive payment instructions according to your choice."); 

          }
          
          this.initDataItem(); 
          this.app.toTop(); 

           
        }else{

          this.app.info("Houve algo inesperado. Tente novamente.","danger");

        }

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);

        this.app.info(error.message,"danger");

      });

    }catch(e){

      this.loader = false; 
      this.app.info(e.message,"danger"); 

    }

  }
  /**
   * 
   * Delete
   * 
   * @param apelido 
   * @param index 
   * @param confirm 
   * 
   */
  _delete(apelido,index=null,confirm=true){

    setTimeout(() => {
      this.content = null;
    },100);

    if(confirm){

      let message = "";

      if(this.locale == "pt"){

        message = "Deseja excluir o serviço ?";

      }else{

        message = "Do you want to delete the service?";

      }

      this.app.confirm(
        message,
        this.delete, 
        this,
        {
          apelido: apelido,
          index: index
        }
      );
      return false;
    }

  }
  /**
   * 
   * Delete
   * 
   * @param data 
   * 
   */
  delete(data){

    let self = data.self;
    data     = data.dataItem;

    switch(data.apelido){
      case "hotel":
        self.dataItem.hotel   = null;
        self.dataItem.quartos = [];
      break;
      case "traslado":
        self.transportes.splice(data.index,1); 
      break;
      case "passeio":
        self.passeios.splice(data.index,1); 
      break;
    }

  }
  /**
   * 
   * Abre o calendário
   * 
   * @param calendar 
   * 
   */
  openCalendar(calendar=1){ 


    if(calendar == 1){
      $(this.dataIn.nativeElement).datepicker("show");
    }else{
      $(this.dataOut.nativeElement).datepicker("show"); 
    }


  }
  /***
   * 
   * Config Dates
   * 
   */
  configDates(){

    try{
    
      let self                 = this;
      let minDate:any          = new Date();
      let minDateFinal:any     = new Date();
      let maxDate              = null;
      let informarPeriodoVenda = this.evento.informar_periodo_venda === true || this.evento.informar_periodo_venda === "true" ? true : false;

      minDate      = this.app.formatDate(this.evento.data_inicio,"/"); 
      minDateFinal = this.app.addDaysMask(minDate,this.dataItem.dias);

      if(informarPeriodoVenda){
        minDate      = this.app.formatDate(this.evento.data_venda_min,"/"); 
        maxDate      = this.app.formatDate(this.evento.data_venda_max,"/"); 
        minDateFinal = this.app.addDaysMask(minDate,this.dataItem.dias);
      }

      let lg           = this.app.getLocale();
      let dayNamesMin  = lg == "pt" ? ['Dom','Seg','Ter','Qua','Qui','Sex','Sab'] : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
      let monthNames   =  ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];

      if(lg == "es"){
        dayNamesMin = ['Dom','Lun','Mar','Mié','Jue','Vie','Sab'];
        monthNames  = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      }else if(lg == "en"){
        monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      }
      
      this.dataInicio = $(this.dataIn.nativeElement).datepicker({
        dateFormat: "dd/mm/yy",
        dayNamesMin: dayNamesMin,
        monthNames: monthNames,
        minDate: minDate,
        maxDate: this.app.subDaysMask(maxDate,this.dataItem.dias),
        onSelect:(date) => {

          self.dataItem.data_in = date;
          self.dataFim.datepicker("option","minDate",date);
          
          date = self.app.addDaysMask(date,self.dataItem.dias);
            
          self.dataItem.data_out = date;

          self.dataFim.datepicker("setDate",self.app.formatDate(date,"/")); 	
          self.dataFim.datepicker("option","minDate",date);

          self.updateDados();

        }

      });
      this.dataInicio.datepicker("setDate",minDate); 

      setTimeout(() => {
      
        this.dataFim = $(this.dataOut.nativeElement).datepicker({
          dateFormat: "dd/mm/yy",
          dayNamesMin: dayNamesMin,
          monthNames: monthNames,
          minDate: minDateFinal,
          onSelect: (date) => {

            self.dataItem.data_out = date;
            self.dataInicio.datepicker("option","maxDate",date);

            self.updateDados();

          }
        }); 
        
        if(maxDate != null){ 

          this.dataFim.datepicker("option","minDate",minDateFinal);
          this.dataFim.datepicker("option","maxDate",maxDate);

        }

        this.dataFim.datepicker("setDate",minDateFinal); 
        this.dataItem.data_in   = this.date.formatDateFromDatepicker(this.dataInicio.datepicker("getDate"))
        this.dataItem.data_out  = this.date.formatDateFromDatepicker(this.dataFim.datepicker("getDate"))
      
      },200);  


    }catch(e){
      
      alert(e.message)

    }

  }
  /**
   * 
   * Atualiza os dados
   * 
   */
  updateDados(){

    let dias           = this.app.diferencaDias($(this.dataIn.nativeElement).datepicker("getDate"),$(this.dataOut.nativeElement).datepicker("getDate"));
    this.dataItem.dias = dias; 

    this.dataItem.data_in   = this.date.formatDateFromDatepicker(this.dataInicio.datepicker("getDate"))
    this.dataItem.data_out  = this.date.formatDateFromDatepicker(this.dataFim.datepicker("getDate"))

  }
  /**
   * 
   * Set parcelas
   * 
   */
  setParcelas(){

    if(this.evento.ativar_parcelamento){

      for(let index = 0; index < this.evento.numero_max_parcelas; index++) {
        
        this.parcelas.push(index+1);
        
      }

    }

  }
  /**
   * 
   * Set bandeiras
   * 
   */
  setBandeiras(){

    this.bandeiras.push({
      titulo: "Visa",
      image: "/images/bandeiras/visa.svg"
    });
    this.bandeiras.push({
      titulo: "Master",
      image: "/images/bandeiras/master.svg"
    });
    this.bandeiras.push({
      titulo: "Amex",
      image: "/images/bandeiras/amex.svg"
    });
    this.bandeiras.push({
      titulo: "Elo",
      image: "/images/bandeiras/elo.png"
    });


  }
  _changeTipoPagamento(v){
    this.dataItem.tipo_pagamento = v;
  }
  /**
   * 
   * Update transportes
   * 
   */
  updateTransportePassageiro(data){

    this.dataItem.transportes = data;

  }
  /**
   * 
   * Update transportes
   * 
   */
  updateHotelPassageiro(data){

    this.dataItem.hoteis = data;
    this._updateHoteis.emit(data);

  }
  /***
   * 
   * Altera o titular
   * 
   */
  _updateTitular(passageiro){

    this.passageiroTitular = passageiro; 
    this.setDadosTitular();

  }
  /**
   * 
   * Seta os dados do titular
   * 
   */
  setDadosTitular(){

    this.dataItem.nome     = this.passageiroTitular.nome;
    this.dataItem.email    = this.passageiroTitular.email;
    this.dataItem.telefone = this.passageiroTitular.telefone;
    this.dataItem.cpf      = this.passageiroTitular.cpf;
    this.dataItem.rg       = this.passageiroTitular.rg; 
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
    try{

      this.getData();
      this.setBandeiras();
      
      setTimeout(() => {
        this.app.setHeaderType.emit(2);
      });
      
    }catch(e){
      
    }

  }
  /**
   * 
   * On Destroy
   * 
   */
  ngOnDestroy(): void {
    this.app.setHeaderType.emit(1);
  }
  /**
   * 
   * On change
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any): void {
    
    if(changes.dataItem.hoteis){ 



    }


  }

}
