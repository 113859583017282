import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-produto-dica-roteiro',
  templateUrl: './produto-dica-roteiro.component.html',
  styleUrls: ['./produto-dica-roteiro.component.scss']
})
export class ProdutoDicaRoteiroComponent implements OnInit {

  @Input("data") data = null;

  constructor() { }

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
