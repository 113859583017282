import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../../../services/api.service';

declare var $:any;

@Component({
  selector: 'btn-visualizar-voucher',
  templateUrl: './btn-visualizar-voucher.component.html',
  styleUrls: ['./btn-visualizar-voucher.component.scss']
})
export class BtnVisualizarVoucherComponent implements OnInit {

  @Input("file") file;
  public loader = false;
  public url    = null; 

  constructor(
    private api: ApiService,
    private location: Location
  ){ }

  /**
   * 
   * Retorna a URL do Voucher do Manager
   * 
   * 
   */
  getUrlVoucher(){

    try{

      this.loader = true;

      this.api.manage().gerarVoucher(this.file).subscribe(response => {

        this.loader = false;

        this.url = $(response.data).find("urlPDF").text(); 
        
        window.open(this.url,"_blank")

   
      },(err) => {

        this.loader = false;

      });

    }catch(e){

      this.loader = false;

    }

  } 
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

    

  }

}
