import { ModulosService } from './../../../services/modulos.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-produtos-categoria',
  templateUrl: './produtos-categoria.component.html',
  styleUrls: ['./produtos-categoria.component.scss']
})
export class ProdutosCategoriaComponent implements OnInit {
 
 
  @Input("data") data;
  @Input("template") template;
  @Input("classCustom") classCustom;
  @Input("classBtn") classBtn;
  public loader    = false;
  public produtos  = null;
  public categoria = null; 


  constructor(
    private modulos: ModulosService
  ) { }

  /**
   * 
   * Busca os dados 
   * 
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.modulos.getProdutosCategoria(this.data.params.produto_categoria_id).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          if(response.data.data.length > 0){
            this.produtos  = response.data.data;
            this.categoria = response.data.categoria;
          }  
        }
        
      },(response) => {

        this.loader = false;

      });

    }catch(e){

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.getData();
  }

}
