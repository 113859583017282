import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clearUrl'
})
export class ClearUrlPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    let data = value.split("?");
    return data[0];
  
  }

}
