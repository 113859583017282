import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcValorTotal'
})
export class CalcValorTotalPipe implements PipeTransform {

  transform(servicos: any): any {
    
    let valor = 0

    servicos.forEach(element => {

      valor += (parseInt(element.qtd_adt)*parseFloat(element.valor_adt)+parseInt(element.qtd_chd)*parseFloat(element.valor_chd))+parseFloat(element.valor_servico)

    });

    return valor

  }

}
