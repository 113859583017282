import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoPaxsDesc'
})
export class TipoPaxsDescPipe implements PipeTransform {

  transform(value: string): string {

    switch(value){
      case "adt":
        return "Adulto";
      case "chd":
        return "Criança (Pagante)";
      case "inf":
        return "Criança (Gratuíta)";
    }

    return "";


  }

}
