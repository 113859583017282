import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';

declare var $:any;

@Component({
  selector: 'produto-avaliacao-form',
  templateUrl: './produto-avaliacao-form.component.html',
  styleUrls: ['./produto-avaliacao-form.component.scss']
})
export class ProdutoAvaliacaoFormComponent implements OnInit, AfterViewInit{
  
  @Input("id") id     = null;
  public dataItem:any = {};
  @ViewChild("range") range:ElementRef;
  @ViewChild("file") file:ElementRef;
  public loader = false;
  public subscribeMessage = {
    data: "",
    status: null
  }

  constructor(
    private api: ApiService,
    private app: AppService
  ) { }


  /***
   * 
   * Envia a avaliação
   * 
   */
  enviar(){

    try{

      this.loader = true;

      var formData = new FormData();
      
      let imagem = typeof($(this.file.nativeElement)[0].files[0]) == "undefined" ? null : $(this.file.nativeElement)[0].files[0];

      formData.append('imagem',imagem);
			formData.append('produto_id',this.id);
			formData.append('nome',this.dataItem.nome);
			formData.append('email',this.dataItem.email);
			formData.append('avaliacao',this.dataItem.avaliacao);
      formData.append('nota',this.dataItem.nota);

      this.subscribeMessage.status = null;

      this.api.produto().avaliacao(formData).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.app.toSection("#form-avaliacao");
          this.subscribeMessage = {
            data: "Avaliação enviada com sucesso.",
            status: 1
          }
          //this.initDataItem();

        }else{
          
          this.app.toSection("#form-avaliacao");
          this.subscribeMessage = {
            data: "Não foi possível enviar a avaliação, verifique as informações digitadas e tente novamente.",
            status: 500
          }

        }
        

      },(response) => {

        this.loader = false;
        let errors  = this.app.formatError(response);
        
        this.subscribeMessage = {
          data: errors.message,
          status: 500
        }
        this.app.toSection("#form-avaliacao");

      });

    }catch(e){

      this.loader = false;
      

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      nota: '5.0', 
      imagem: "",
      avaliacao: ""
    }

  }
  /**
   * 
   * Init Range
   * 
   */
  initRange(){

    let self = this;

    function my_prettify (num) {
      self.dataItem.nota = num.toFixed(1);
      return num;
    }

    $(this.range.nativeElement).ionRangeSlider({
      type: "single",
      min: 1,
      max: 5,
      from: this.dataItem.nota,
      prettify: my_prettify
    });

  }
  changeRange(value){

    alert("aham")
    this.dataItem.nota = $(this.range.nativeElement).val();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.initRange()
    this.initDataItem();
  }
  ngAfterViewInit(): void {
    
  }

}
