import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'modulo-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.scss']
})
export class ServicosComponent implements OnInit {

  @Input("data") data = null;
  public servicos     = [];
  public texto:any = null;

  constructor(
    private app: AppService
  ) { }

  /**
   * 
   * Seta os serviços
   * 
   */
  setServicos(){
    
    this.servicos = this.data.servicos;

  }
  /**
   * 
   * Set texto
   * 
   */
  setTexto(){

    let lg = this.app.getLocale();

    if(lg == "es"){
      this.texto = this.data.params.texto_es;
    }else if(lg == "en"){
      this.texto = this.data.params.texto_en;
    }else{
      this.texto = this.data.params.texto;
    }
    

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   **/ 
  ngOnInit(){
    this.setServicos();
    this.setTexto();
  }

}
