import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'passeio-item-two', 
  templateUrl: './passeio-item-two.component.html',
  styleUrls: ['./passeio-item-two.component.scss']
})
export class PasseioItemTwoComponent implements OnInit {

  @Input("data") data                = null;
  @Output("changeValue") changeValue = new EventEmitter(); 
  @Input("clearInput") clearInput    = new EventEmitter();
  @Output("next") next = new EventEmitter();  
  @Input("clearAll") clearAll        = new EventEmitter();   

  public fotoCapa     = {
    imagem: "/images/brocker-eventos/brocker-eventos.jpg",
    alt: "Brocker Eventos"
  };
  public lg               = "pt";
  public quantidade       = 1;
  public comboQuantidade  = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
  public dataItem:any     = null;
  public subscribeMessage = null;
  public isSelecionado    = false;

  constructor(
    private app: AppService,
  ){

    this.lg = this.app.getLocale(); 

  }
  /**
   * 
   * Seta a capa
   * 
   */
  setCapa(){

    for(let index = 0;index < this.data.fotos.length;index++) {
      
      if(this.data.fotos[index].capa == true){

        this.fotoCapa = this.data.fotos[index];
        break;

      }
      
    }

  }
  /**
   * 
   * Mais
   * 
   */
  mais(){

    this.quantidade++; 

    if(this.isSelecionado){

      this.selecionar();

    }
  
  }
  /**
   * 
   * Menos
   * 
   */
  menos(){

    if(this.quantidade > 1){
      
      this.quantidade--;

      if(this.isSelecionado){

        this.selecionar();
  
      }

    }

  }
  /**
   * 
   * Seleciona esse passeio
   * 
   */
  selecionar(deletar=false){

    this.changeValue.emit({ 
      quantidade: this.quantidade,
      passeio: this.data,
      deletar: deletar
    });

    if(!deletar){
      
      this.isSelecionado = true;

    }else{ 

      this.isSelecionado = false;

    }

  }
  /**
   * 
   * Next
   * 
   */
  _next(){

    this.next.emit();

  }
  /**
   * 
   * On data  
   * 
   */
  onData(){

    this.clearAll.subscribe(() => {

      this.quantidade    = 1;
      this.isSelecionado = false;
      
    });

  }
  /***
   * 
   * Inicializa s Funções
   * 
   * 
   */
  ngOnInit(){
    this.setCapa();
    this.onData();
  }

}
