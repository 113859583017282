import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'btn-visualizar-boleto',
  templateUrl: './btn-visualizar-boleto.component.html',
  styleUrls: ['./btn-visualizar-boleto.component.scss']
})
export class BtnVisualizarBoletoComponent implements OnInit {

  @Input("link") link = null;

  constructor() { }

  ngOnInit() {
  }

}
