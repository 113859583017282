import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'box-form-pesquisa',
  templateUrl: './box-form-pesquisa.component.html',
  styleUrls: ['./box-form-pesquisa.component.scss']
})
export class BoxFormPesquisaComponent implements OnInit,OnChanges {
  
  @Input("show") show       = false;
  @Output("close") close  = new EventEmitter();
  @ViewChild("box") box:ElementRef; 
  @ViewChild("input") input:ElementRef; 
  public dataItem = {termo: ""}

  constructor(
    private router: Router
  ) { }

  /**
   * 
   * Buscar
   * 
   */
  buscar(){

    this.show = false;
    this.close.emit(false);
    $(this.box.nativeElement).slideUp(200);
    this.router.navigate(["/buscar"],{queryParams:this.dataItem});

  }
  setFocusInput(){

    this.input.nativeElement.focus();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }
  ngOnChanges(changes:any): void {
    
    if(changes.show){
      if(changes.show.currentValue == true){
        $(this.box.nativeElement).slideDown(200);
        this.setFocusInput();
      }else{
        $(this.box.nativeElement).slideUp(200);
      }
    }

  }

}
