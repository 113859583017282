import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ValidatorService } from '../../../services/validator.service';

declare var $:any;

@Component({
  selector: 'transporte-modal-valores',
  templateUrl: './transporte-modal-valores.component.html',
  styleUrls: ['./transporte-modal-valores.component.scss']
})
export class TransporteModalValoresComponent implements OnInit {

  @Input("data") data:any = null;
  @Input("open") open   = false;
  @ViewChild("modal") modal:ElementRef;
  @ViewChild("body") body:ElementRef;
  @Output("changeValue") changeValue = new EventEmitter();
  @Output("add") add = new EventEmitter();
  @Input("clear") clear = new EventEmitter();
  public lang = "pt";
  public subscribeMessage = null;
  public quantidade       = 1;
  public title  = "";

  public fotoCapa     = null;
  public fotos        = [];
  public quartos      = [];
  public dataItem:any = {};
  public comboQuantidade  = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

  constructor(
    public app: AppService,
    public validator: ValidatorService
  ){ 

    this.lang = this.app.getLocale();
    this.initDataItem();

  }
  /**
   * 
   * 
   * 
   * 
   */
  closeModal(){
    
    $(this.modal.nativeElement).modal("hide");
    this.open = false;
    this.changeValue.emit(this.open); 
    $("html").css({
      "overflow": "auto"
    });
    
  }
  /**
   * 
   * Abre o modal
   * 
   */
  openModal(){

    $(this.modal.nativeElement).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    $("html").css({
      "overflow": "hidden"
    });

  }
  /**
   * 
   * Seta as fotos
   * 
   */
  setFotos(){

    this.fotos    = [];
    this.fotoCapa = {};

    for(let index = 0; index < this.data.fotos.length; index++) {
      
      if(!this.data.fotos[index].capa){
        this.fotos.push(this.data.fotos[index]);
      }else{
        this.fotoCapa = this.data.fotos[index];
      } 

    }
  

  }
  /***
   * 
   * 
   */
  selecionar(quarto){

    let i = null;

    for(let index = 0; index < this.quartos.length; index++) {
      
      if(this.quartos[index].id == quarto.id){

        i = index;
        break;

      }
      
    }
    if(i == null){
      
      this.quartos.push(quarto);
      quarto.selecionado = true;
    
    }else{

      this.quartos.splice(i,1);
      quarto.selecionado = false;

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      voo_data: "",
      voo_numero: "",
      voo_hora: ""
    }
    this.quantidade = 1;    

  }
  /**
   * 
   * Adicionar
   * 
   * 
   */
  adicionar(){

    this.subscribeMessage = null;
    this.validator.clear();
    
    if(this.lang == "pt"){

      this.validator.isEmpty(this.dataItem.voo_data,"voo_data","- Informe a Data.");
      this.validator.isDate(this.dataItem.voo_data,"voo_data","- A Data é inválida.");
      this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Informe o Número.");
      this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Informe a Hora.");
      this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- A Hora é inválida.");

    }else{

      this.validator.isEmpty(this.dataItem.voo_data,"voo_data","- Enter the Date.");
      this.validator.isDate(this.dataItem.voo_data,"voo_data","- Invalid Date.");
      this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Enter the Number.");
      this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Enter the Time.");
      this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- Invalid Time."); 

    }

    if(this.validator.passes()){

      this.add.emit({
        voo_data: this.dataItem.voo_data,
        voo_numero: this.dataItem.voo_data,
        voo_hora: this.dataItem.voo_hora,
        quantidade: this.quantidade
      });
      
      this.closeModal();

    }else{

      let errors = this.validator.getMessagesHtml();

      this.subscribeMessage = errors;
      

    }
    //this.add.emit(this.quartos);
    //this.closeModal();

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.clear.subscribe(response => {

      this.quartos = [];

      for(let index = 0; index < this.data.quartos.length; index++) {
        
        this.data.quartos[index].selecionado = false;

      }

    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setFotos();
    this.onData();
    
  }
  /**
  * 
  * Verifica quando
  * 
  * @param changes 
  * 
  */
  ngOnChanges(changes: any): void {
       
    if(changes.open){
      if(changes.open.currentValue == true || changes.open.currentValue == "true"){
        this.openModal();
      }
    }
    if(changes.data){
      this.setFotos();
    }

  }

}
