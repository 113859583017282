import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-horarios',
  templateUrl: './horarios-modulo.component.html',
  styleUrls: ['./horarios-modulo.component.scss']
})
export class HorariosModulo implements OnInit {

  @Input("data") data;
  @Input("template") template;

  constructor(){ }

  
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {

  }

}
