import { Injectable } from '@angular/core';

declare var PhotoSwipe:any;
declare var PhotoSwipeUI_Default:any;
declare var initPhotoSwipeFromDOM: any;

@Injectable()
export class PhotoswipeService {

  constructor() { }

  /**
   * 
   * Inicializa  a Galeria
   * 
   * @param gallerySelector 
   * 
   */
  initGallery(gallerySelector){
    
    initPhotoSwipeFromDOM(gallerySelector);
  
  }
  

}
