import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'evento-form-busca',
  templateUrl: './evento-form-busca.component.html',
  styleUrls: ['./evento-form-busca.component.scss']
})
export class EventoFormBuscaComponent implements OnInit {

  public termo = "";

  constructor(
    private router: Router
  ){ }

  enviar(){

    this.router.navigate(["/eventos"],{queryParams:{termo: this.termo}}); 

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
