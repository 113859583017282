import { Component, OnInit, OnChanges, ComponentRef, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { AppService } from '../../../services/app.service';
import { Subscription, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ConteudoService } from '../../../services/conteudo.service';

@Component({
  selector: 'eventos-page',
  templateUrl: './eventos-page.component.html',
  styleUrls: ['./eventos-page.component.scss']
})
export class EventosPage implements OnInit { 

  public loader        = false;
  public categoria     = null;
  public produtos      = [];
  public cidades      = [];
  public classificacao = [];
  public periodos      = [];
  public apelido       = "eventos";
  public messageLoader = "Carregando Serviços...";
  public response      = {
    data: "",
    status: null
  }
  public itensBreadcrumbs      = [];
  public mySubscription:Subscription;
  public destroyed             = new Subject<any>();
  public sub:any;
  public destroy               = false;
  public descricao             = null;
  public produtosOriginal      = [];
  public paramsSearch          = {
    nome: "",
    produto_categoria_id: "",
    produto_regiao_id: ""
  }
  public page       = null;
  public fotos      = []; 
  public params     = {};
  public categorias = [];
  public valores    = [];
  public pageSize   = '6';
  public pageNumber = 1;
  public termo        = null;
  public apelido_dica = null;
  public idCidade     = null;
  public apelido_categoria = null;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private app: AppService,
    private _router: Router,
    private conteudo: ConteudoService
  ){ 
    
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        
        this.termo  = params['termo'] || null;

        if(this.termo != null){
          this.getData();
        }

    });
    
  }
  /***
   * 
   * Update data serviço
   * 
   */
  updateDataProdutos(data){

    this.produtos = data;
    //this.app.toSection("#produtos"); 

  }
  /**
   * 
   * Retorna os dados da página
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true;
      let params  = {
        termo: this.termo
      };

      this.conteudo.getPageByApelido(this.apelido,params).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){ 
                  
            this.page             = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.produtosOriginal = Object.assign([],response.data.page.eventos);
            this.produtos         = response.data.page.eventos;       
            
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description);
              
          }
          
          this.response = {
            data: "",
            status: 1
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      alert(e.message)
    }

  }
  /**
   * 
   * Ouvinte
   * 
   */
  onData(){

    this.app.emitChangePage.subscribe(response => {

      let apelido = typeof(response.apelido) != "undefined" ? response.apelido : null;

      if(apelido != null){

        if(apelido != this.apelido){

          this.apelido = apelido;
          this.getData();

        }

      }

    });

  }
  /***
   * 
   * Change
   * 
   */
  _change(dataItem){

    this.paramsSearch = dataItem;
    this._search();

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  _search(){ 

    let self     = this;
    let data:any = [];
    let status   = false;
    let nome     = ""; 

    for(let index = 0; index < this.produtosOriginal.length; index++) {
        
      status = false; 
      nome   = this.app.formatarApelido(this.produtosOriginal[index].nome,false);

      if(this.paramsSearch.nome !== ""){ 

        if(nome.indexOf(this.app.formatarApelido(this.paramsSearch.nome,false)) != -1){
          status = true;
        }

      }else{
        status = true; 
      }

      if(this.paramsSearch.produto_categoria_id !== ""){

        if(this.paramsSearch.nome !== ""){

          if((this.produtosOriginal[index].produto_categoria_id == this.paramsSearch.produto_categoria_id) && (nome.indexOf(this.paramsSearch.nome.toLowerCase()) != -1)){
            status = true; 
          }else{
            status = false;
          }

        }else{

          if(this.produtosOriginal[index].produto_categoria_id == this.paramsSearch.produto_categoria_id){
            status = true;
          }else{
            status = false;
          }

        }
      }
      if(this.paramsSearch.produto_regiao_id !== ""){

        if(this.paramsSearch.produto_categoria_id !== "" && this.paramsSearch.nome !== ""){
  
          if((this.produtosOriginal[index].produto_categoria_id == this.paramsSearch.produto_categoria_id) && (this.produtosOriginal[index].produto_regiao_id == this.paramsSearch.produto_regiao_id) && (nome.indexOf(this.paramsSearch.nome.toLowerCase()) != -1)){
            status = true; 
          }else{
            status = false;
          }

        }else if(this.paramsSearch.nome !== ""){

          if((this.produtosOriginal[index].produto_regiao_id == this.paramsSearch.produto_regiao_id) && (nome.indexOf(this.paramsSearch.nome.toLowerCase()) != -1)){
            status = true; 
          }else{
            status = false;
          }

        }else{
  
          if(this.produtosOriginal[index].produto_regiao_id == this.paramsSearch.produto_regiao_id){
            status = true;
          }else{
            status = false;
          }
  
        }
        
      }   
      if(status){
        data.push(this.produtosOriginal[index]);
      }  
        
    }

    this.produtos = data;

  }
  /**
   * 
   * Set Breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    if(this.termo != null){

      this.itensBreadcrumbs.push({
        text: "Eventos"
      });

      this.itensBreadcrumbs.push({
        text: 'Resultados para termo: "'+this.termo+'"'
      });
      
    }else{
      
      this.itensBreadcrumbs.push({
        text: "Eventos",
        routerLink: "/eventos"
      });

    }
    
  
  }
  /**
   * 
   * Change Page
   * 
   */
  changePage(number){

    this.pageNumber = number;
    this.app.toSection("#produtos");

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
    this.app.toTop();
  
    if(this.termo == null && this.idCidade == null){
      this.getData();
    }  
    this.onData();
    if(this.apelido_dica == null && this.apelido_categoria == null){
      this.setItensBreadcrumbs(); 
    }  

    this._router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {

      if(!this.destroy){
        let apelido = this.route.params["_value"].apelido;
        if(typeof(apelido) != "undefined"){
          this.getData();
        }  
      }

    });

  }
  /**
   * 
   * Destrói 
   * 
   */
  ngOnDestroy():void{

    this.sub.unsubscribe();
    if(this.mySubscription){
      this.destroy = true; 
      this.mySubscription.unsubscribe(); 
    }
    
  }

}
