import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'produtoValor'
})
export class ProdutoValorPipe implements PipeTransform {

  transform(valor: any,index: any): any {
    
    let v = valor.split(".");

    return v[index];

  }

}
