import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ValidatorService } from '../../../services/validator.service';
import { TransporteModalDetalheComponent } from '../transporte-modal-detalhe/transporte-modal-detalhe.component';
import { MatDialog } from '@angular/material/dialog';

declare var $:any;

@Component({
  selector: 'transporte-item-two',
  templateUrl: './transporte-item-two.component.html',
  styleUrls: ['./transporte-item-two.component.scss']
})
export class TransporteItemTwoComponent implements OnInit,AfterViewInit {

  @Input("data") data:any            = null;
  @Output("changeValue") changeValue = new EventEmitter(); 
  @Input("clearInput") clearInput    = new EventEmitter();
  @Output("next") next               = new EventEmitter();   
  @ViewChild("vooData") vooData:ElementRef;
  @ViewChild("vooDataOut") vooDataOut:ElementRef;
  @Input("dataIn") dataIn:any        = new EventEmitter(); 

  public fotoCapa     = {
    imagem: "/images/brocker-eventos/brocker-eventos.jpg",
    alt: "Brocker Eventos"
  };
  public lg               = "pt";
  public quantidade       = 1;
  public comboQuantidade  = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
  public dataItem:any     = null;
  public subscribeMessage = null;
  public openModal        = false;
  public openModalValores = false;
  public isSelecionado    = false;
  @Input("clearAll") clearAll    = new EventEmitter(); 
 
  
  constructor(
    private app: AppService,
    private validator: ValidatorService,
    private dialog: MatDialog
  ){ 

    this.lg = this.app.getLocale(); 

  } 
  
/**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      aeroporto_id: null,
      voo_data: "",
      voo_numero: "",
      voo_hora: "",
      aeroporto_out_id: null,
      voo_data_out: null,
      voo_numero_out: null,
      voo_hora_out: null,
      quantidade:1,
      quantidade_chd:0,
      hotel: "",
      data_fixa: null,
      data_fixa_out: null,
      evento_transporte_data_id: null,
      evento_transporte_data_out_id: null,
      utilizar_data_fixa: this.data.utilizar_data_fixa,
      ativar_datas: this.data.ativar_datas,
      aeroporto_ativo: this.data.aeroporto_ativo
    }
    this.quantidade = 1;

  }
  /**
   * 
   * Change Data In
   * 
   */
  changeSelectData(){

    let item = this.getDataItem(this.dataItem.evento_transporte_data_id);

    this.dataItem.data_fixa = item;

  }
  /**
   * 
   * Change Data Out
   * 
   */
  changeSelectDataOut(){

    let item = this.getDataItem(this.dataItem.evento_transporte_data_out_id);

    this.dataItem.data_fixa_out = item;


  }
  getDataItem(id){

    let i = null;

    for(let index = 0; index < this.data.datas.length; index++) {
      
      if(id == this.data.datas[index].id){

        i = this.data.datas[index];
        break;

      }
      
    }

    return i;

  }
  /**
   * 
   * Seta a capa
   * 
   */
  setCapa(){

    for(let index = 0;index < this.data.fotos.length;index++) {
      
      if(this.data.fotos[index].capa == true){

        this.fotoCapa = this.data.fotos[index];
        break;

      }
      
    }

  }
  /**
   * 
   * Selecionar
   * 
   */
  selecionar(){

    this.subscribeMessage = null;
    this.validator.clear();
    
    if(!this.dataItem.utilizar_data_fixa){

      if(this.lg == "pt"){

        this.validator.isEmpty(this.dataItem.hotel,"hotel","- Informe o Hotel.");

        if(this.data.tipo_trajeto == 3){

          if(this.dataItem.ativar_datas){

            if(this.dataItem.aeroporto_ativo){

              this.validator.isEmpty(this.dataItem.aeroporto_id,"aeroporto_id","- Informe o Aeroporto de Chegada.");

            }

            this.validator.isEmpty(this.dataItem.voo_data,"voo_data","- Informe a Data de Chegada.");
            this.validator.isDate(this.dataItem.voo_data,"voo_data","- A Data de Chegada é inválida.");
            this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Informe o Número de Chegada.");
            this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Informe a Hora de Chegada.");
            this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- A Hora de Chegada é inválida.");

            if(this.dataItem.aeroporto_ativo){

              this.validator.isEmpty(this.dataItem.aeroporto_out_id,"aeroporto_out_id","- Informe o Aeroporto da Partida.");

            }

            this.validator.isEmpty(this.dataItem.voo_data_out,"voo_data_out","- Informe a Data de Partida.");
            this.validator.isDate(this.dataItem.voo_data_out,"voo_data_out","- A Data de Partida é inválida.");
            this.validator.isEmpty(this.dataItem.voo_numero_out,"voo_numero_out","- Informe o Número de Partida.");
            this.validator.isEmpty(this.dataItem.voo_hora_out,"voo_hora_out","- Informe a Hora de Partida.");
            this.validator.isTime(this.dataItem.voo_hora_out,"voo_hora_out","- A Hora de Partida é inválida.");
          }

        }else{
          if(this.dataItem.ativar_datas){
            this.validator.isEmpty(this.dataItem.voo_data,"voo_data","- Informe a Data.");
            this.validator.isDate(this.dataItem.voo_data,"voo_data","- A Data é inválida.");
            this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Informe o Número.");
            this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Informe a Hora.");
            this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- A Hora é inválida.");
          }
        }
        
        
      }else if(this.lg == "es"){
        
        if(this.data.tipo_trajeto == 3){

          if(this.dataItem.ativar_datas){

            if(this.dataItem.aeroporopo){

            }

            this.validator.isEmpty(this.dataItem.voo_data,"voo_data","- Introduce la fecha de llegada.");
            this.validator.isDate(this.dataItem.voo_data,"voo_data","- La fecha de llegada no es válida.");
            this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Ingrese el número de llegada.");
            this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Informar hora de llegada.");
            this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- La hora de llegada no es válida.");

            this.validator.isEmpty(this.dataItem.voo_data_out,"voo_data_out","- Introduce la fecha de salida.");
            this.validator.isDate(this.dataItem.voo_data_out,"voo_data_out","- La fecha de salida no es válida.");
            this.validator.isEmpty(this.dataItem.voo_numero_out,"voo_numero_out","- Ingrese el número de salida.");
            this.validator.isEmpty(this.dataItem.voo_hora_out,"voo_hora_out","- Introduzca la hora de salida.");
            this.validator.isTime(this.dataItem.voo_hora_out,"voo_hora_out","- La hora de salida no es válida.");
          }

        }else{
          this.validator.isTime(this.dataItem.hotel,"hotel","- Entrar en el hotel."); 
          if(this.dataItem.ativar_datas){
            this.validator.isEmpty(this.dataItem.voo_data,"voo_data","-Ingrese la fecha.");
            this.validator.isDate(this.dataItem.voo_data,"voo_data","- Fecha invalida.");
            this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Ingrese el Número.");
            this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Ingrese el tiempo.");
            this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- Hora inválida."); 
          }
        }

      }else{

        if(this.data.tipo_trajeto == 3){

          if(this.dataItem.ativar_datas){
            this.validator.isEmpty(this.dataItem.voo_data,"voo_data","- Enter the Arrival Date.");
            this.validator.isDate(this.dataItem.voo_data,"voo_data","- Arrival Date is invalid.");
            this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Enter the Arrival Number.");
            this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Inform Arrival Time.");
            this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- Arrival time is invalid.");

            this.validator.isEmpty(this.dataItem.voo_data_out,"voo_data_out","- Enter the Departure Date.");
            this.validator.isDate(this.dataItem.voo_data_out,"voo_data_out","- Departure Date is invalid.");
            this.validator.isEmpty(this.dataItem.voo_numero_out,"voo_numero_out","- Enter the Departure Number.");
            this.validator.isEmpty(this.dataItem.voo_hora_out,"voo_hora_out","- Enter Departure Time.");
            this.validator.isTime(this.dataItem.voo_hora_out,"voo_hora_out","- Departure Time is invalid.");
          }

        }else{

          this.validator.isTime(this.dataItem.hotel,"hotel","- Enter the Hotel."); 
          if(this.dataItem.ativar_datas){
            this.validator.isEmpty(this.dataItem.voo_data,"voo_data","- Enter the Date.");
            this.validator.isDate(this.dataItem.voo_data,"voo_data","- Invalid Date.");
            this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Enter the Number.");
            this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Enter the Time.");
            this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- Invalid Time."); 
          }

        }
        
      }
    }else{

      if(this.lg == "pt"){

        this.validator.isEmpty(this.dataItem.hotel,"hotel","- Informe o Hotel.");
        if(this.dataItem.ativar_datas){
          this.validator.isEmpty(this.dataItem.evento_transporte_data_id,"evento_transporte_data_id","- Selecione uma Data.");
        }
        if(this.data.tipo_trajeto == 3 && this.dataItem.ativar_datas){
          this.validator.isEmpty(this.dataItem.evento_transporte_data_out_id,"evento_transporte_data_out_id","- Selecione a Data de Partida.");
        }

      }else{

        this.validator.isTime(this.dataItem.hotel,"hotel","- Enter the Hotel."); 
        if(this.dataItem.ativar_datas){
          this.validator.isEmpty(this.dataItem.evento_transporte_data_id,"evento_transporte_data_id","- Select a Date.");
        }
        if(this.data.tipo_trajeto == 3 && this.dataItem.ativar_datas){
          this.validator.isEmpty(this.dataItem.evento_transporte_data_out_id,"evento_transporte_data_out_id","- Select a Date Out.");
        }

      }

    }

    if(this.validator.passes()){

      this.changeValue.emit({
        transporte: this.data,
        voo_data: this.dataItem.voo_data,
        voo_numero: this.dataItem.voo_numero,
        voo_hora: this.dataItem.voo_hora,
        voo_data_out: this.dataItem.voo_data_out,
        voo_numero_out: this.dataItem.voo_numero_out,
        voo_hora_out: this.dataItem.voo_hora_out,
        quantidade: this.dataItem.quantidade,
        quantidade_chd: this.dataItem.quantidade_chd,
        deletar: false,
        hotel: this.dataItem.hotel,
        utilizar_data_fixa: this.dataItem.utilizar_data_fixa,
        data_fixa: this.dataItem.data_fixa,
        data_fixa_out: this.dataItem.data_fixa_out,  
        evento_transporte_data_id: this.dataItem.evento_transporte_data_id,
        evento_transporte_data_out_id: this.dataItem.evento_transporte_data_out_id,
        aeroporto_id: this.dataItem.aeroporto_id,
        aeroporto_out_id: this.dataItem.aeroporto_out_id
      });

      this.isSelecionado = true;

    }else{

      let errors = this.validator.getMessagesHtml();

      this.subscribeMessage = errors; 

    }
    

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.clearInput.subscribe((transporte_id) => {

      if(transporte_id != this.data.id){
        this.initDataItem(); 
      }

    });
    this.clearAll.subscribe(() => {

      this.initDataItem();
      this.isSelecionado = false;

    });

  }
  /**
   * 
   * Open modal
   * 
   */
  _openModal(){

    //this.openModal = true;
    this.dialog.open( 
      TransporteModalDetalheComponent 
    ,{
      width: "600px",
      data: {
        transporte: this.data
      },
      panelClass: 'custom-dialog-container'
    }); 

  }
  /**
   * 
   * Open modal
   * 
   */
  _openModalValores(){

    this.openModalValores = true;

  }
  /***
   * 
   * 
   * 
   */
  deletar(data){

    this.changeValue.emit({
      transporte: this.data,
      voo_data: this.dataItem.voo_data,
      voo_numero: this.dataItem.voo_data,
      voo_hora: this.dataItem.voo_hora,
      quantidade: this.dataItem.quantidade,
      deletar: true 
    });
    this.isSelecionado = false;

  }
  /**
   * 
   * Change select
   * 
   */
  changeSelectTraslado(){

    if(this.isSelecionado){

      this.changeValue.emit({
        transporte: this.data,
        voo_data: this.dataItem.voo_data,
        voo_numero: this.dataItem.voo_data,
        voo_hora: this.dataItem.voo_hora,
        quantidade: this.dataItem.quantidade,
        quantidade_chd: this.dataItem.quantidade_chd,
        data_fixa: this.dataItem.data_fixa,
        utilizar_data_fixa: this.dataItem.utilizar_data_fixa,
        evento_transporte_data_id: this.dataItem.evento_transporte_data_id,
        evento_transporte_data_out_id: this.dataItem.evento_transporte_data_out_id,
        deletar: false
      });

    }

  }
  /**
   * 
   * Next
   * 
   */
  _next(){

    this.next.emit();

  }
  /***
   * 
   * Config Dates
   * 
   */
  configDates(){

    try{
    
      let self         = this;
      let dateFormat   = this.lg == "pt" ? "dd-mm-yy" : "yy-mm-dd";
      let dayNamesMin  = this.lg == "pt" ? ['Dom','Seg','Ter','Qua','Qui','Sex','Sab'] : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
      
      if(this.lg == "es"){
        dayNamesMin = ['Dom','Lun','Mar','Mié','Jue','Vie','Sab'] ;
      }

      $(this.vooData.nativeElement).datepicker({
        dateFormat: dateFormat,
        dayNamesMin: dayNamesMin,
        minDate: new Date(), 
        showOn: "button",
        buttonImage: "images/icons/calendar.svg",
        onSelect:(date) => {
 
          self.dataItem.voo_data = date;

        }
      }); 
      setTimeout(() => {
        
        let d   = this.dataIn.split("-");
        let ano = d[0];
        let mes = parseInt(d[1])-1; 
        let dia = d[2];

        this.dataItem.voo_data = dia+"-"+d[1]+"-"+ano;
        
        $(this.vooData.nativeElement).datepicker("setDate",new Date(ano,mes,dia));

      },500);

      $(this.vooDataOut.nativeElement).datepicker({
        dateFormat: dateFormat,
        dayNamesMin: dayNamesMin,
        minDate: new Date(), 
        showOn: "button",
        buttonImage: "images/icons/calendar.svg",
        onSelect:(date) => {
 
          self.dataItem.voo_data_out = date;

        }
      }); 
      setTimeout(() => {
        
        let d   = this.dataIn.split("-");
        let ano = d[0];
        let mes = parseInt(d[1])-1; 
        let dia = d[2];

        this.dataItem.voo_data_out = dia+"-"+d[1]+"-"+ano;
        
        $(this.vooDataOut.nativeElement).datepicker("setDate",new Date(ano,mes,dia));

      },500);
               
           
    }catch(e){

    }

  }
  /**
   * 
   * Get dates
   * 
   */
  getDates(datas,option){

    let data = [];

    for (let index = 0; index < datas.length; index++) {
      
      if(datas[index].tipo_data == option){

        data.push(datas[index]);  

      }
      
    }

    return data;

  }
  /**
   * 
   * Convert Date
   * 
   */
  convertDate(data){

    let _data = data.split("-");

    return _data[2]+"-"+_data[1]+"-"+_data[0];

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  ngOnInit(){
    this.initDataItem();
    this.onData();
    this.setCapa(); 
  }
  ngAfterViewInit(): void {
    this.configDates();
  }
  


}
