import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PhotoswipeService } from '../../../services/photoswipe.service';

@Component({
  selector: 'galeria-one',
  templateUrl: './galeria-one.component.html',
  styleUrls: ['./galeria-one.component.scss']
})
export class GaleriaOneComponent implements OnInit, AfterViewInit {
  
  @Input("fotos") fotos = [];
  @ViewChild("galeria") galeria:ElementRef;
  @Input("classCustom") classCustom = "";

  constructor(
    private PhotoswipeService: PhotoswipeService
  ) { 

  }
  initGaleria(){

    this.PhotoswipeService.initGallery(".galeria-one");

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }
  ngAfterViewInit(): void {
    this.initGaleria();
  }

}
