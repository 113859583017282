import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'btn-visualizar-carne',
  templateUrl: './btn-visualizar-carne.component.html',
  styleUrls: ['./btn-visualizar-carne.component.scss']
})
export class BtnVisualizarCarneComponent implements OnInit {

  @Input("link") link = null;
  
  constructor() { }

  ngOnInit() {
  }

}
