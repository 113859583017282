import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-parceiros', 
  templateUrl: './parceiros.component.html',
  styleUrls: ['./parceiros.component.scss']
})
export class ParceirosComponent implements OnInit {

  @Input("data") data;
  @Input("template") template;
  public dataItem:any = {};
  public loader       = false;
  public parceiros    = [];
  
  constructor(){ }

  /**
   * 
   * Seta os parceiros
   * 
   */
  setParceiros(){

    this.parceiros = this.data.parceiros;
    
  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setParceiros();
  }

}
