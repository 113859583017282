import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  @Input("data") data;
  @Input("template") template;

  constructor(){ 
  }

  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
  
  }



}
