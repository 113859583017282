import { Component, OnInit, Input } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-destaque-blog',
  templateUrl: './destaque-blog.component.html',
  styleUrls: ['./destaque-blog.component.scss']
})
export class DestaqueBlogComponent implements OnInit {

  @Input("data") data;
  public posts  = [];

  constructor() { }

  /**
   * 
   * Get DATAs
   * 
   */
  getData(){

    try{

      let self = this;

      $.getJSON(
        "https://blog.brockerturismo.com.br/wp-json/wp/v2/posts?per_page=4",
        function(data){ 
          self.setPosts(data,self);
        }
      );

    }catch(e){
      alert(e.message);
    }


  }
  /**
   * 
   * Set posts
   * 
   */
  setPosts(data,self){

    self.posts = [];

    for (let index = 0; index < data.length; index++) {
      
      self.posts.push({
        titulo: data[index].title.rendered,
        imagem: data[index].jetpack_featured_media_url,
        link: data[index].link,
        descricao: data[index].content.rendered.substring(0,145)+"..."
      });
      
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.getData();
  }

}
