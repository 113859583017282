import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pswp-template',
  templateUrl: './pswp-template.component.html',
  styleUrls: ['./pswp-template.component.scss']
})
export class PswpTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
