import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'destino-servicos-page',
  templateUrl: './destino-servicos-page.component.html',
  styleUrls: ['./destino-servicos-page.component.scss']
})
export class DestinoServicosPage implements OnInit {

  public apelido  = null;
  public loader   = false;
  public response = {
    data: "",
    status: null
  }
  public regiao        = null;
  public produtos      = [];
  public messageLoader = "Carregando Serviços. Aguarde!"; 
  public itensBreadcrumbs = [];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private app: AppService,
    private router: Router
  ){ 
    this.apelido = typeof(this.route.params["_value"].apelido) != "undefined" ? this.route.params["_value"].apelido : null;
  }
  
  /***
   * 
   * Retorna os produtos
   * 
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.produto().regiao_produtos(this.apelido).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          if(response.data.regiao == null){
            this.router.navigateByUrl("/");
          }
          this.response.status = 1;
          this.regiao        = response.data.regiao; 
          this.produtos      = response.data.produtos; 

          this.setItensBreadcrumbs();

          this.app.setMetaDados(this.regiao.meta_title,this.regiao.meta_description);
          this.app.toTop();

        }else{

          this.response = {
            data: response.data,
            status: 500
          }

        }

      },(response) => {

        this.loader = false;

        let error = this.app.formatError(response);

        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader = false;

    }

  }
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Serviços",
      routerLink: "/servicos"
    });
    if(this.regiao != null){
      
      this.itensBreadcrumbs.push({
        text: this.regiao.titulo, 
        routerLink: "/servicos/"+this.regiao.apelido
      });

    }  
    

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.getData();
  }

}
