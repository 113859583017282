import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var $:any;

@Component({
  selector: 'transporte-modal-detalhe',
  templateUrl: './transporte-modal-detalhe.component.html',
  styleUrls: ['./transporte-modal-detalhe.component.scss']
})
export class TransporteModalDetalheComponent implements OnInit {

  @Input("data") data:any = null;
  @Input("open") open       = false;
  @ViewChild("modal") modal:ElementRef; 
  @ViewChild("body") body:ElementRef; 
  @Output("changeValue") changeValue = new EventEmitter();
  public title  = "";
  
  public fotoCapa     = {
    imagem: "/images/brocker-eventos/brocker-eventos.jpg",
    alt: "Brocker Eventos"
  };
  public fotos    = [];

  constructor(
    public dialog: MatDialogRef<TransporteModalDetalheComponent>,
    @Inject(MAT_DIALOG_DATA) public _data
  ){ 

    this.data = this._data.transporte;
    this.setFotos();

  }

  /**
   * 
   * 
   * 
   * 
   */
  closeModal(){
    
    /*$(this.modal.nativeElement).modal("hide");
    this.open = false;
    this.changeValue.emit(this.open); 
    $("html").css({
      "overflow": "auto"
    });*/
    this.dialog.close();
    
  }
  /**
   * 
   * Abre o modal
   * 
   */
  openModal(){

    $(this.modal.nativeElement).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    $("html").css({
      "overflow": "hidden"
    });

  }
  /**
   * 
   * Seta as fotos
   * 
   */
  setFotos(){

    this.fotos    = [];

    if(this.data.fotos.length > 0){
      for(let index = 0; index < this.data.fotos.length; index++) {
        
        if(!this.data.fotos[index].capa){
          this.fotos.push(this.data.fotos[index]);
        }else{
          this.fotoCapa = this.data.fotos[index];
        }

      }
    }  
  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    
  }
  /**
  * 
  * Verifica quando
  * 
  * @param changes 
  * 
  */
  ngOnChanges(changes: any): void {
       
    if(changes.open){
      if(changes.open.currentValue == true || changes.open.currentValue == "true"){
        this.openModal();
      }
    }
    if(changes.data){
      this.setFotos();
    }
    
 }

}
