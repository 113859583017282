import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var $:any;

@Component({
  selector: 'hotel-modal-detalhe',
  templateUrl: './hotel-modal-detalhe.component.html',
  styleUrls: ['./hotel-modal-detalhe.component.scss']
})
export class HotelModalDetalheComponent implements OnInit,OnChanges {

  @Input("hotel") hotel:any = null;
  @Input("open") open   = false;
  @ViewChild("modal") modal:ElementRef;
  @ViewChild("body") body:ElementRef;
  @Output("changeValue") changeValue = new EventEmitter();
  public title  = "";
  
  public fotoCapa     = {
    imagem: "/images/brocker-eventos/brocker-eventos.jpg",
    alt: "Brocker Eventos"
  };
  public fotos    = [];

  constructor(
    public dialog: MatDialogRef<HotelModalDetalheComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ){ 

    this.hotel = this.data.hotel;
    this.setFotos(); 

  } 
  /**
   * 
   * 
   * 
   * 
   */
  closeModal(){


    this.dialog.close();
    /*$(this.modal.nativeElement).modal("hide");
    this.open = false;
    
    this.changeValue.emit(this.open); 
    $("html").css({
      "overflow": "auto"
    });*/
    
  }
  /**
   * 
   * Abre o modal
   * 
   */
  openModal(){

    $(this.modal.nativeElement).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    $("html").css({
      "overflow": "hidden"
    });

  }
  /**
   * 
   * Seta as fotos
   * 
   */
  setFotos(){

    this.fotos    = [];

    for(let index = 0; index < this.hotel.fotos.length; index++) {
      
      if(!this.hotel.fotos[index].capa){
        this.fotos.push(this.hotel.fotos[index]);
      }else{
        this.fotoCapa = this.hotel.fotos[index];
      }

    }
  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setFotos();
    ///this.hotel = this.data.hotel;
    
  }
  /**
  * 
  * Verifica quando
  * 
  * @param changes 
  * 
  */
  ngOnChanges(changes: any): void {
       
    if(changes.open){
      if(changes.open.currentValue == true || changes.open.currentValue == "true"){
        this.openModal();
      }
    }
    if(changes.hotel){
      this.setFotos();
    }
    
 }

}
