import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Router } from '@angular/router';

declare var window:any;

@Component({
  selector: 'select-lg',
  templateUrl: './select-lg.component.html',
  styleUrls: ['./select-lg.component.scss']
})
export class SelectLgComponent implements OnInit {
  
  @ViewChild("dropdownMenu") dropdownMenu: ElementRef;
  @Input('classCustom') classCustom = "";
  private display = "none";
  public label    = "";

  constructor(
    private settings: AppService,
    private app: AppService
  ){ }
  
  /**
   * 
   * Ação on Click
   * 
   */
  onClick(){

    this.display =  this.display == "none" ? "inline" : "none";
    this.dropdownMenu.nativeElement.style.display = this.display;

  }
  /**
   * 
   * 
   * 
   */
  onClickLg(lg){

    this.app.setLocale(lg);
    
    let data = window.location.href.split("?");
    
    location.href = data[0]+"?lg="+lg;
    //location.reload(true); 

  }
  /**
   * 
   * Seta o valor do label
   * 
   */
  setLabel(){

    if(this.settings.getLocale() == "pt"){

      this.label = "Português";

    }else if(this.settings.getLocale() == "en"){

      this.label = "Inglês";

    }else{

      this.label = "Espanhol"; 

    }
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLabel();
  }

}
