import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ValidatorService } from '../../../services/validator.service';

@Component({
  selector: 'transporte-item',
  templateUrl: './transporte-item.component.html',
  styleUrls: ['./transporte-item.component.scss']
})
export class TransporteItemComponent implements OnInit {

  @Input("data") data = null;
  @Output("changeValue") changeValue = new EventEmitter(); 
  @Input("clearInput") clearInput    = new EventEmitter();

  public fotoCapa     = {
    imagem: "/images/brocker-eventos/brocker-eventos.jpg",
    alt: "Brocker Eventos"
  };
  public lang             = "pt";
  public quantidade       = 1;
  public comboQuantidade  = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
  public dataItem:any     = null;
  public subscribeMessage = null;
  public openModal        = false;
  public openModalValores = false;

  constructor(
    private app: AppService,
    private validator: ValidatorService
  ){ 

    this.lang = this.app.getLocale();

  } 
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      voo_data: "",
      voo_numero: "",
      voo_hora: ""
    }
    this.quantidade = 1;

  }
  /**
   * 
   * Seta a capa
   * 
   */
  setCapa(){

    for(let index = 0;index < this.data.fotos.length;index++) {
      
      if(this.data.fotos[index].capa == true){

        this.fotoCapa = this.data.fotos[index];
        break;

      }
      
    }

  }
  /**
   * 
   * Selecionar
   * 
   */
  selecionar(){

    this.subscribeMessage = null;
    this.validator.clear();
    
    if(this.lang == "pt"){

      this.validator.isEmpty(this.dataItem.voo_data,"voo_data","- Informe a Data.");
      this.validator.isDate(this.dataItem.voo_data,"voo_data","- A Data é inválida.");
      this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Informe o Número.");
      this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Informe a Hora.");
      this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- A Hora é inválida.");

    }else{

      this.validator.isEmpty(this.dataItem.voo_data,"voo_data","- Enter the Date.");
      this.validator.isDate(this.dataItem.voo_data,"voo_data","- Invalid Date.");
      this.validator.isEmpty(this.dataItem.voo_numero,"voo_numero","- Enter the Number.");
      this.validator.isEmpty(this.dataItem.voo_hora,"voo_hora","- Enter the Time.");
      this.validator.isTime(this.dataItem.voo_hora,"voo_hora","- Invalid Time."); 

    }

    if(this.validator.passes()){

      this.changeValue.emit({
        transporte: this.data,
        voo_data: this.dataItem.voo_data,
        voo_numero: this.dataItem.voo_data,
        voo_hora: this.dataItem.voo_hora,
        quantidade: this.quantidade
      });

    }else{

      let errors = this.validator.getMessagesHtml();

      this.subscribeMessage = errors; 

    }
    

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.clearInput.subscribe((transporte_id) => {

      if(transporte_id != this.data.id){
        this.initDataItem(); 
      }

    });

  }
  /**
   * 
   * Open modal
   * 
   */
  _openModal(){

    this.openModal = true;

  }
  /**
   * 
   * Open modal
   * 
   */
  _openModalValores(){

    this.openModalValores = true;

  }
  /***
   * 
   * 
   * 
   */
  adicionar(data){

    this.changeValue.emit({
      transporte: this.data,
      voo_data: data.voo_data,
      voo_hora: data.voo_hora,
      voo_numero: data.voo_numero,
      quantidade: data.quantidade 
    }); 

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  ngOnInit(){
    this.initDataItem();
    this.onData();
    this.setCapa(); 
  }

}
