import { TranslateService } from './../services/translate.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateParams'
})
export class TranslateParamsPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ){

  }

  transform(value: any,params: any,campo:string): any {
    
    return this.translate.getValueParams(value,params,campo);
    
  }

}
