import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiComponentesSettings } from '../class/api-componentes-settings';
import { ApiModulosSettings } from '../class/api-modulos-settings';

@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient
  ){ }

  /**
   * 
   * Contato
   * 
   */
  pedido():any{

    return{
      finalizar_pedido: (dataItem):any=> {

        let url = ApiComponentesSettings.PEDIDO.finalizar_pedido; 
        return this.http.post(url,dataItem);
      
      }
    
    }

  }
  /**
   * 
   * Contato
   * 
   */
  contato():any{

    return{
      fale_conosco: (dataItem):any=> {

        let url = ApiModulosSettings.CONTATO.fale_conosco; 
        return this.http.post(url,dataItem)
      
      }
    
    }

  }
  /**
   * 
   * Eventos
   * 
   */
  evento():any{

    return {
      evento: (apelido):any=> {

        let url = ApiComponentesSettings.EVENTO.evento+"?apelido="+apelido; 
        return this.http.get(url,{})
      
      },
      search: (dataItem):any=> {

        let url    = ApiComponentesSettings.EVENTO.search;
        let params = dataItem;  

        return this.http.get(url,{params})
      
      }
    }

  }
  /**
   * 
   * Serviço
   * 
   */
  produto():any{

    return {
      destino_produtos: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.destino_produtos+"?apelido="+apelido; 
        return this.http.get(url,{})
      
      },
      regiao_produtos: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.regiao_produtos+"?apelido="+apelido; 
        return this.http.get(url,{})
      
      },
      produtos: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.produtos+"?apelido="+apelido; 
        return this.http.get(url,{})
      
      },
      search: (params):any=> {

        let url = ApiComponentesSettings.PRODUTO.search; 
        return this.http.get(url,{params})
      
      },
      cidade: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.cidade+"/"+apelido; 
        return this.http.get(url,{})
      
      },
      send_contato: (dataItem):any=> {

        let url = ApiComponentesSettings.PRODUTO.send_contato; 
        return this.http.post(url,dataItem)
      
      },
      categorias: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.categorias; 
        return this.http.get(url,{})
      
      },
      produto: (apelido):any=> {

        let url = ApiComponentesSettings.PRODUTO.produto+"?apelido="+apelido; 
        return this.http.get(url,{})

      },
      solicitacao_reserva: (dataItem):any=> {

        let url = ApiComponentesSettings.PRODUTO.solicitacao_reserva; 

        return this.http.post(url,dataItem)

      },
      avaliacao: (dataItem):any=> {

        let url = ApiComponentesSettings.PRODUTO.avaliacao; 

        return this.http.post(url,dataItem)

      },
      avaliacoes: (id,skip):any=> {

        let url = ApiComponentesSettings.PRODUTO.avaliacoes+"/"+id+"/"+skip; 

        return this.http.get(url,{})

      },
      aeroporto:  {
        combo: ():any => {
          
          let url = ApiComponentesSettings.PRODUTO.aeroporto.combo; 
          return this.http.get(url,{})
        
        }
      },
      cia_aerea:  {
        combo: () => {
          
          let url = ApiComponentesSettings.PRODUTO.cia_aerea.combo; 
          return this.http.get(url,{})
        
        }
      }
    }

  }
   /**
   * 
   * Serviço
   * 
   */
  atividade():any{

    return {
      get_atividade: (codigo):any=> {
        let url = ApiComponentesSettings.ATIVIDADE.get_atividade+"?codigo="+codigo; 
        return this.http.get(url,{})
      },
      get_atividades_pax: (id):any=> {
        let url = ApiComponentesSettings.ATIVIDADE.get_atividades_pax+"/"+id; 
        return this.http.get(url,{})
      }
    }

  }
  /**
   * 
   * Cart
   * 
   * 
   */
  cart():any{

    return{
      get: ():any => {

        let url = ApiComponentesSettings.CART.base;
        
        return this.http.get(url,{})

      },
      insert: (params):any => {
        
        let url = ApiComponentesSettings.CART.base;

        return this.http.post(url,params)

      },
      delete: (id):any => {
        
        let url = ApiComponentesSettings.CART.base+"/"+id;

        return this.http.delete(url)

      },
      lista_paxs: ():any => {

        let url = ApiComponentesSettings.CART.lista_paxs;
        
        return this.http.get(url,{})

      },
      abandonar_compra: ():any => {

        let url = ApiComponentesSettings.CART.abandonar_compra;
        
        return this.http.post(url,{})

      },
      esvaziar: ():any => {

        let url = ApiComponentesSettings.CART.esvaziar;
        
        return this.http.post(url,{})

      },
      dados_comprador: (params):any => {

        let url = ApiComponentesSettings.CART.dados_comprador;
        
        return this.http.post(url,params)

      },
      get_cart_pagamento: ():any => {

        let url = ApiComponentesSettings.CART.get_cart_pagamento;
        
        return this.http.get(url,{})

      },
      get_cart_file_gerado: ():any => {

        let url = ApiComponentesSettings.CART.get_cart_file_gerado;
        
        return this.http.get(url,{})

      },
      update_passageiro_titular: (id):any => {

        let url    = ApiComponentesSettings.CART.update_passageiro_titular;

        let params = {
          id: id
        }
        
        return this.http.put(url,params) 

      }

    }

  }
  /**
   * 
   * Managetour
   * 
   */
  manage():any{

    return{
      gerarVoucher: (file):any => {

        let url = ApiComponentesSettings.MANAGE.gerar_voucher+"/"+file;
          
        return this.http.get(url)

      },
      listaTiposServicosById: (id,idMacroRegiao):any => {

        let url = ApiComponentesSettings.MANAGE.lista_tipo_servicos_by_id+"/"+id+"/"+idMacroRegiao; 
        return this.http.get(url)

      },
      hoteis: (id=null): any => {

        let url  = ApiComponentesSettings.MANAGE.hoteis;
        if(id != null){
          url += "/"+id;
        }

        return this.http.get(url)

      },
      regioes: (): any => {

        let url  = ApiComponentesSettings.MANAGE.regioes;
        return this.http.get(url)

      },
      consultarReserva: (data): any => {

        let url  = ApiComponentesSettings.MANAGE.consultar_reserva;
        
        let params = {
          file: data.file,
          ultimonome: data.ultimonome
        } 
        
        return this.http.get(url,{params})

      },
      autenticar: (usuario,senha,tipo):any => {

        let url = ApiComponentesSettings.MANAGE.autenticar;    
        
        let params = {
          usuario: usuario,
          senha: senha,
          tipo: tipo
        }

        return this.http.post(url,params)

      },
      logout: ():any => {

        let url = ApiComponentesSettings.MANAGE.logout;
        
        return this.http.post(url,{})

      },
      disponibilidade: (
        idServico,
        idTipoServico,
        idMacroRegiao,
        idTipoTransporte,
        mes,
        ano
      ):any => {

        let url   = ApiComponentesSettings.MANAGE.disponibilidade;
        let params = {
          macro_regiao_id: idMacroRegiao,
          servico_id: idServico,
          tipo_servico_id: idTipoServico,
          tipo_transporte_id: idTipoTransporte,
          mes: mes,
          ano: ano
        }
        return this.http.get(url,{params})

      }

    }

  }
  /**
   * 
   * Pagamento
   * 
   */
  pagamento():any{

    return {
      finalizarReserva: (params):any => {

        let url = ApiComponentesSettings.PAGAMENTO.finalizar_reserva;

        return this.http.post(url,params)

      },
      enviarConfirmacaoEmail: (email,id):any => {

        let url = ApiComponentesSettings.PAGAMENTO.enviar_confirmacao_email;
        let params = {
          id: id,
          email: email
        }

        return this.http.post(url,params)

      }

    }

  }

}
