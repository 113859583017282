import { Injectable } from '@angular/core';
import { AppSettings } from '../class/app-settings';

@Injectable()
export class StorageService {

  constructor() { }

  /**
   * 
   * Set Data File
   * 
   */
  setDataFile(dataParams,dataResponse){

    let prefixo = AppSettings.PREFIXO;
    let params = {
      dataItem: dataParams,
      response: dataResponse
    }

    sessionStorage.setItem(prefixo+"dataFile",JSON.stringify(params));

  }
  /**
   * 
   * Get Data File
   * 
   */
  getDataFile(){

    let prefixo = AppSettings.PREFIXO;
    let data = sessionStorage.getItem(prefixo+"dataFile");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }

  }
  /**
   * 
   * Set Data File
   * 
   */
  setUltimoServico(data){

    let prefixo = AppSettings.PREFIXO;

    sessionStorage.setItem(prefixo+"ultimoServico",JSON.stringify(data));

  }
  /**
   * 
   * Get Data File
   * 
   */
  getUltimolServico(){

    let prefixo = AppSettings.PREFIXO;
    let data = sessionStorage.getItem(prefixo+"ultimoServico");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }

  }
  /**
   * 
   * 
   */
  remoteUltimolServico(){

    let prefixo = AppSettings.PREFIXO;
    
    sessionStorage.removeItem(prefixo+"ultimoServico");

  }

}
