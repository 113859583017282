import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'mat-modal-info',
  templateUrl: './mat-modal-info-dialog.component.html',
  styleUrls: ['./mat-modal-info-dialog.component.scss']
})
export class MatModalInfoDialogComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<MatModalInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  /**
   * 
   * Close modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
  
  }

}
