import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';

declare var $:any;

@Component({
  selector: 'consultar-servicos',
  templateUrl: './consultar-servicos.component.html',
  styleUrls: ['./consultar-servicos.component.scss']
})
export class ConsultarServicosComponent implements OnInit {

  public open         = false;
  public text         = "Consulte seus Serviços";
  public dataItem:any = {};
  public loader       = false;
  @ViewChild("modal") modal:ElementRef;
  public response = {
    data: "",
    status: null
  }
  public data = null;
  public servicos = [];

  constructor(
    private api: ApiService,
    private app: AppService
  ){ }

  /***
   * 
   * Busca os dados do file
   * 
   */
  buscar(){

    try{

      this.loader = true;

      this.api.manage().consultarReserva(this.dataItem).subscribe((response) => {

        this.loader = false;

        this.setOption();
        this._modal().show();

        if(response.status == 1){

          this.data     = response.data;
          this.servicos = typeof(this.data.programacaoFile.Servico[0]) != "undefined" ? this.data.programacaoFile.Servico : [this.data.programacaoFile.Servico];
          for (let index = 0; index < this.servicos.length; index++) {
            
            this.servicos[index].show = false;
            
          }
          this.response.status = 1;

        }else{

          this.response = {
            data: response.data,
            status: response.status
          }
          
        }
      

      },(response) => {

        this.loader = false;

      });


    }catch(e){

      this.loader = false;


    }

  }
  /**
   * 
   * Seta
   * 
   */
  setOpen(){

    this.open = true;

  }
  /**
   * 
   * @param data 
   * 
   * 
   */
  showPassageiros(servico){

    servico.show = servico.show == true ? false : true;

  }
  /**
   * 
   * @param data 
   * 
   */
  getPassageiros(data){

    return typeof(data[0]) != "undefined" ? data : [data];

  }
  /**
   * 
   * Set Option
   * 
   */
  setOption(){

    this.open = this.open == true ? false : true;

    if(this.open == true){
      this.text = "Fechar";
    }else{
      this.text = "Consulte seus Serviços";
    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      file: "",
      ultimonome: ""
    }

  }
  /**
   * 
   * Config modal
   * 
   */
  _modal(){

    return{
      show: () => {
        $(this.modal.nativeElement).modal("show");
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
      },
      hide: () => {
        $(this.modal.nativeElement).modal("hide");
      }
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.initDataItem();
  }

}
