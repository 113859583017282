import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-unidades',
  templateUrl: './unidades.component.html',
  styleUrls: ['./unidades.component.scss']
})
export class UnidadesComponent implements OnInit {

  @Input("data") data     = null;
  @Input("template") template = null;

  constructor() { }

  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
