import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-template-one',
  templateUrl: './produto-template-one.component.html',
  styleUrls: ['./produto-template-one.component.scss']
})
export class ProdutoTemplateOneComponent implements OnInit {

  @Input("produto") produto         = null;
  @Input("classCustom") classCustom = "";
  @Input("classBtn") classBtn = "";

  constructor(){ }


  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){

  }

}
