import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-eventos-destaque',
  templateUrl: './eventos-destaque.component.html',
  styleUrls: ['./eventos-destaque.component.scss']
})
export class EventosDestaqueComponent implements OnInit {

  @Input("data") data         = null;
  @Input("template") template = null;
  public eventos              = [];

  constructor() { }

  /**
   * 
   * Seta os eventos 
   * 
   */
  setEventos(){

    this.eventos = this.data.eventos; 
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setEventos();
  }

}
