import { MatSelect } from '@angular/material/select';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'combo-parcelas-cartao',
  templateUrl: './combo-parcelas-cartao.component.html',
  styleUrls: ['./combo-parcelas-cartao.component.scss']
})
export class ComboParcelasCartaoComponent implements OnInit {

  @Input("label") label              = "Parcelas:";
  @Output("changeValue") changeValue = new EventEmitter();
  @Input("max") max                  = 12;
  @Input("total") total              = 0;
  @ViewChild('select') select: MatSelect;
  public data   = [];
  public loader = false;
  public value  = null;
  public response = {
    data: "",
    status: null
  }
  public parcelas = [];

  constructor(){
     
  }

  /**
   * 
   * 
   * 
   */
  clickValue(parcela){
 
    this.changeValue.emit(parcela); 

  }
  /**
   * 
   * Populaas parcelas
   * 
   * 
   */
  populaParcelas(){
    
    this.parcelas = [];
    
    for (var index = 1; index <= this.max; index++) {
          
      this.parcelas.push(index);
         
    }
    
  }
  /**
   * 
   * Inicializa o dataItem
   * 
   * 
   */
  ngOnInit(){
    this.populaParcelas();
  }

}
