import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-avaliacao',
  templateUrl: './produto-avaliacao.component.html',
  styleUrls: ['./produto-avaliacao.component.scss']
})
export class ProdutoAvaliacaoComponent implements OnInit {

  @Input("valor") valor             = 5;
  @Input("classCustom") classCustom = "";
  public data                       = [];

  constructor(){ }

  /***
   * 
   * Set a Data
   * 
   */
  setData(){

    this.valor = this.valor === null || this.valor === 0 ? 5 : this.valor;

    for (let index = 1; index <= 5;index++) {
      
      this.data.push({
        class: index <= this.valor ? "fas fa-star" : "far fa-star"
      });    
      
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setData();
  }
  

}
