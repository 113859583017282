import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-item-campeao-venda',
  templateUrl: './produto-item-campeao-venda.component.html',
  styleUrls: ['./produto-item-campeao-venda.component.scss']
})
export class ProdutoItemCampeaoVendaComponent implements OnInit {

  @Input("data") produto:any            = {};
  @Input("categoria") categoria:any     = null;

  public link = null;

  constructor(){ 

  }
  /**
   * 
   * Seta o link para o detalhe do produto
   * 
   */
  setLink(){

    if(this.categoria ==  null){
      this.link = "/servicos/"+this.produto.categoria_apelido+"/"+this.produto.apelido;
    }else{
      this.link = "/servicos/"+this.categoria.apelido+"/"+this.produto.apelido;
    }  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLink();
  }

}
