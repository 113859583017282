import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, Event as NavigationEvent } from '@angular/router';
import { AppService } from '../../services/app.service';

declare var $:any;

@Component({
  selector: 'modulo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {

  @ViewChild("header") header: ElementRef;
  public show   = false;
  public isHome = true; 
  public tipo   = 1;

  constructor(
    public router: Router,
    public app: AppService
  ){ }

  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
  setTopFixed(){

    let header = this.header.nativeElement;

    $(window).scroll(function(){
      
      var fromTop = $(this).scrollTop();
      var windowWidth = $(window).width();
      
      if(fromTop > 100 && windowWidth > 960){
        header.classList.add("animated","fadeIn","fixed");
      }else{
        header.classList.remove("fixed","animated","fadeIn");
      }

    });

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){


    this.app.setHeaderType.subscribe((tipo) => {

      this.tipo = tipo;

    });

    this.router.events.forEach((event: NavigationEvent) => {
      
      let e:any = event;
      if(e.url == "/"){
        this.isHome = true;
      }else{
        this.isHome = false;
      }

    });
  
    if(this.router.url == "/"){
      this.isHome = true;
    }else{
      this.isHome = false;
    }

    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.onData();
  }
  ngAfterViewInit(){

    this.show = true;
    this.setTopFixed();
    this.onData();

  }
}
