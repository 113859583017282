import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-categoria-item',
  templateUrl: './produto-categoria-item.component.html',
  styleUrls: ['./produto-categoria-item.component.scss']
})
export class ProdutoCategoriaItemComponent implements OnInit {

  @Input("categoria") categoria:any = {};

  constructor(){ 

  }

  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){
  
  }

}
