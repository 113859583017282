import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(
    private app:AppService,
    private data: DataService  
  ){ }

  setMetaTags(){

    let config      = this.data.getConfiguracao();
    let title       = config.meta_title;
    let description = config.meta_description;
    
    this.app.setMetaDados(null,description);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    
    this.app.toTop();
    this.setMetaTags(); 
    
  }

}
