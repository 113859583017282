import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'modulo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  constructor(
    private app: AppService
  ){ } 

  /**
   * 
   * Sobe para o topo
   * 
   */
  toTop(){

    this.app.toTop();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
