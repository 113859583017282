import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'error-custom',
  templateUrl: './error-custom.component.html',
  styleUrls: ['./error-custom.component.scss']
})
export class ErrorCustomComponent implements OnInit {

  @Input("title")       title       = "Houve um erro";
  @Input("description") description = "Um erro inesperado aconteceu e não foi possível carregar as informações.";
  @Input("details")     details     = null;
  @Output("reload")     reload      = new EventEmitter();
  @Input("textButton")  textButton  = "RECARREGAR";
  @Input("activeButton") activeButton:any = true;

  constructor() { }
  
  convertBoolean(){

    this.activeButton = this.activeButton == true || this.activeButton == "true" ? true : false;

  }
  /**
   * 
   * reload
   * 
   */
  reloadData(){

    this.reload.emit(null);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    this.convertBoolean();
  }

}
