import { AppSettings } from './../../class/app-settings';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'site-desabilitado-page',
  templateUrl: './site-desabilitado-page.component.html',
  styleUrls: ['./site-desabilitado-page.component.scss']
})
export class SiteDesabilitadoPageComponent implements OnInit {

  public name = AppSettings.name;

  constructor() { }

  ngOnInit() {
  }

}
