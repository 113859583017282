import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'produto-venda-offline',
  templateUrl: './produto-venda-offline.component.html',
  styleUrls: ['./produto-venda-offline.component.scss']
})
export class ProdutoVendaOfflineComponent implements OnInit {

  @Input("servico") servico:any               = {};
  @Input("configuracaoLoja") configuracaoLoja = null; 
  public dataItem:any                         = {}; 
  public qtd_adt = 1;
  public loader  = false; 
  public message = {
    data: "",
    class: "",
    show: false
  };
  public messageLoader = "Enviando Solicitação. Aguarde!"; 

  constructor(
    public app: AppService,
    public api: ApiService
  ){ }

  /***
   * 
   * Send
   * 
   */
  send(){

    try{

      this.loader = true;

      this.api.produto().solicitacao_reserva(this.dataItem).subscribe((response) => {

        this.loader = false;

        switch(response.status){
          case 1:
            this.message.data = "Seus dados foram enviados com sucesso.";
            this.message.show = true;
            this.message.class = "alert alert-success";
            this.initDataItem();
          break;
          case 0:
            let errors = this.app.getErrors([response.data]);
            
            this.message.data = errors;
            this.message.show = true;
            this.message.class = "alert alert-danger";
          break;
          default:
            this.message.data  = "Houve um erro interno. Tente mais tarde ou informe nossa equipe.";
            this.message.show  = true;
            this.message.class = "alert alert-danger";
          break;
        }


      }, (response) => {

        this.loader = false;

        let error          = this.app.formatError(response);

        this.loader        = false;
        this.message.data  = error.message; 
        this.message.show  = true;
        this.message.class = "alert alert-danger";

      });
      

    }catch(e){

      this.loader = false;


    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      produto: this.servico.nome,
      messagem: "",
      qtd_adultos: 1,
      qtd_melhor_idade: 0,
      qtd_criancas_pagantes: 0,
      qtd_criancas_cortesia: 0
    }
    
  }
  /**
   * 
   * Set labels
   * 
   */
  setLabels(){

    if(this.servico.passageiro_label_informar){

      this.configuracaoLoja.passageiro_label_adt      = this.servico.passageiro_label_adt;
      this.configuracaoLoja.passageiro_label_chd      = this.servico.passageiro_label_chd;
      this.configuracaoLoja.passageiro_label_snr      = this.servico.passageiro_label_snr;
      this.configuracaoLoja.passageiro_label_chd_free = this.servico.passageiro_label_chd_free;

    }

  }
  /**
   * 
   * Adiciona
   * 
   */
  mais(campo){

    switch(campo){
      case 'adt':
        this.dataItem.qtd_adultos++;
      break;
      case 'chd':
        this.dataItem.qtd_criancas_pagantes++;
      break;
      case 'snr':
        this.dataItem.qtd_melhor_idade++;
      break;
      case 'inf':
        this.dataItem.qtd_criancas_cortesia++;
      break;
    }

  }
  /**
   * 
   * Diminui
   * 
   */
  menos(campo){
    
    switch(campo){
      case 'adt':
        if(this.dataItem.qtd_adultos > 1){
          this.dataItem.qtd_adultos--;
        }  
      break;
      case 'chd':
        if(this.dataItem.qtd_criancas_pagantes >= 1){
          this.dataItem.qtd_criancas_pagantes--;
        }  
      break;
      case 'snr':
        if(this.dataItem.qtd_melhor_idade >= 1){
          this.dataItem.qtd_melhor_idade--;
        }  
      break;
      case 'inf':
        if(this.dataItem.qtd_criancas_cortesia >= 1){
          this.dataItem.qtd_criancas_cortesia--;
        }  
      break;
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setLabels();
    this.initDataItem();
  }

}
