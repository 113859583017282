import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'error-one',
  templateUrl: './error-one.component.html',
  styleUrls: ['./error-one.component.scss']
})
export class ErrorOneComponent implements OnInit {
  
  @Input("message") message = "Houve um erro no servidor"
  @Output("emitError") emitError  = new EventEmitter()  

  constructor() { }

  reloadPage(){

    this.emitError.emit(null);

  }

  ngOnInit() {
    
  }

}
