import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'produto-avaliacao-lista',
  templateUrl: './produto-avaliacao-lista.component.html',
  styleUrls: ['./produto-avaliacao-lista.component.scss']
})
export class ProdutoAvaliacaoListaComponent implements OnInit {

  @Input("mediaData") mediaData   = null;
  @Input("media") media:any       = null;
  @Input("data") data:any         = null;
  @Input("produtoId") produtoId   = null;
  public info  = "";
  public lista = [];
  public total = 0;
  public loader = false;
  public showMore = true;

  constructor(
    private api: ApiService,
    private app: AppService
  ){ }

  /**
   * 
   * 
   * 
   * 
   */
  getData(id){

    try{

      this.loader = true; 

      this.api.produto().avaliacoes(this.produtoId,this.data.length).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          if(response.data.length > 0){
            for (let index = 0; index < response.data.length; index++) {
              
              this.data.push(response.data[index]);
              
            }
            if(response.data.length < 3){
              this.showMore = false;
            }
          }else{
            this.showMore = false;
          }

        }

      },(response) => {

        this.loader = false;

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Set Data
   * 
   */
  setData(){

    if(this.media != null){
      this.media = parseFloat(this.media).toFixed(1); 
    }
    if(this.media <= 2){
      this.info = "Razoável!";
    }else if(this.media <= 3){
      this.info = "Bom!";
    }else if(this.media < 5){
      this.info = "Muito Bom!";
    }else{
      this.info = "Excelente!";
    }
    if(this.mediaData != null){

      this.total = this.mediaData.excelente+this.mediaData.muito_bom+this.mediaData.bom+this.mediaData.razoavel;
    
      this.lista.push({
        titulo: "Excelente!",
        nota: "5.0",
        percentual: this.calcularPercentual(this.mediaData.excelente)
      });
      this.lista.push({
        titulo: "Muito Bom!",
        nota: "4.0",
        percentual: this.calcularPercentual(this.mediaData.muito_bom)
      });
      this.lista.push({
        titulo: "Bom!",
        nota: "3.0",
        percentual: this.calcularPercentual(this.mediaData.bom)
      });
      this.lista.push({
        titulo: "Razoável!",
        nota: "2.0",
        percentual: this.calcularPercentual(this.mediaData.razoavel)
      });

    }
    if(this.data.length < 3){
      this.showMore = false;
    }

  }
  /**
   * 
   * Calcula o percentual 
   * 
   */
  calcularPercentual(valor){

    if(valor == 0){
      return "0%";
    }else{

      return ((100*valor)/this.total)+"%";

    }


  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setData();
  }

}
