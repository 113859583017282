import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-produto-categoria-destaque',
  templateUrl: './produto-categoria-destaque.component.html',
  styleUrls: ['./produto-categoria-destaque.component.scss']
})
export class ProdutoCategoriaDestaqueComponent implements OnInit {

  @Input("data") data;
  @Input("classCustom") classCustom = "";
  @Input("template") template = null;  
  public produtos             = [];
  public categorias           = [];
  public produtosArray        = new Array();
  
  constructor() { }
  
  /**
   * 
   * Seta os produtos
   * 
   */
  setProdutos(){
    this.produtos = this.data.produtos
    
  }
  /***
   * 
   * Seta os produtos para o Array
   * 
   */
  setProdutosArray(){

    let count         = 0;
    let linha         = [];
    let produtosArray = [];
    let indexArray    = 0;

    for (let index = 0;index < this.produtos.length;index++) {

      if(count <= 4){

        if(typeof(this.produtosArray[indexArray]) == "undefined"){
          this.produtosArray[indexArray] = [this.produtos[index]];
        }else{
          this.produtosArray[indexArray].push(this.produtos[index]);
        }  
        count++;
        
      }else{

        count = 0;
        indexArray++;
        this.produtosArray[indexArray] = [this.produtos[index]];

       
      }
      

        
      
    }
    


  }
  /**
   * 
   * 
   * 
   */
  getProdutoOfArray(produtos,i){


    let out = [];

    if(typeof(produtos[i]) != "undefined"){

      for(i;i < produtos.length;i++) {
        
        out.push(produtos[i]);
        
      }

    }

    return out;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setProdutos();
    this.setProdutosArray();
  }

}
