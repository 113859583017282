import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'hospedagem-item',
  templateUrl: './hospedagem-item.component.html',
  styleUrls: ['./hospedagem-item.component.scss']
})
export class HospedagemItemComponent implements OnInit {

  @Input("data") data                = null;
  @Output("changeValue") changeValue = new EventEmitter(); 
  @Input("clearInput") clearInput    = new EventEmitter();
  @Output("next") next               = new EventEmitter(); 
  public clear                       = new EventEmitter(); 

  public fotoCapa     = {
    imagem: "/images/brocker-eventos/brocker-eventos.jpg",
    alt: "Brocker Eventos"
  };
  public estrelas  = [];
  public quarto    = "";
  public locale    = "pt";
  public openModal        = false; 
  public openModalValores = false;

  constructor(
    private app: AppService
  ){ 

    this.locale = this.app.getLocale();

  }

  /**
   * 
   * Next
   * 
   */
  _next(){

    this.next.emit();

  }
  /**
   * 
   * Seta a capa
   * 
   */
  setCapa(){

    for(let index = 0;index < this.data.fotos.length;index++) {
      
      if(this.data.fotos[index].capa == true){

        this.fotoCapa = this.data.fotos[index];
        break;

      }
      
    }

  }
  /**
   * 
   * On change
   * 
   */
  onChange(){

    this.changeValue.emit({
      hotel: this.data,
      quarto: this.quarto
    }); 

  }
  /**
   * 
   * 
   * 
   */
  setEstrelas(){

    for(let index = 0; index < this.data.estrelas; index++) {
      
      this.estrelas.push(index);
      
    }

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.clearInput.subscribe((hotel_id) => {

      if(hotel_id != this.data.id){

        this.clear.emit();

      }

    });

  }
  /**
   * 
   * Open modal
   * 
   */
  _openModal(){

    this.openModal = true;

  }
  /**
   * 
   * Open modal
   * 
   */
  _openModalValores(){

    this.openModalValores = true;

  }
  /***
   * 
   * 
   * 
   */
  adicionar(quartos){

    this.changeValue.emit({
      hotel: this.data,
      quartos: quartos
    }); 

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setCapa();
    this.setEstrelas();
    this.onData();
  }

}
