import { AppSettings } from './../../class/app-settings';
import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {

  public name = AppSettings.NAME;

  constructor(){ }

  toTop(){

    $("html,body").animate({
      scrollTop: 0
    },500);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {
  }

}
