import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-servicos-oferecidos',
  templateUrl: './servicos-oferecidos.component.html',
  styleUrls: ['./servicos-oferecidos.component.scss']
})
export class ServicosOferecidosComponent implements OnInit {

  @Input("data") data;
  @Input("template") template;  
  public servicos = [];

  constructor() { }

  /**
   * 
   * Seta os dados dos serviços
   * 
   */
  setServicos(){

    this.servicos.push({
      icon: "speaker_notes",
      titulo: "Divulgação",
      texto: "Criamos e disparamos newsletter para divulgação do evento e serviços disponibilizados para o evento, além de inserções de notícias nas redes sociais."
    });
    this.servicos.push({
      icon: "laptop_mac",
      titulo: "Hotsite Exclusivo",
      texto: "Disponibilizamos no site da empresa, um hotsite exclusivo para cada evento, com todas as informações e valores para consulta e venda online."
    });
    this.servicos.push({
      icon: "business_center",
      titulo: "Personalização da Viagem",
      texto: "Nossa equipe personaliza roteiros exclusivos para cada evento e viagem."
    });
    this.servicos.push({
      icon: "directions_bus",
      titulo: "Frota Própria",
      texto: "Dispomos de veículos executivos, vans, micro-ônibus, ônibus, Bustour e Jardineiras para seu evento."
    });
    this.servicos.push({
      icon: "people",
      titulo: "Assessoria e gerenciamento de eventos Corporativos e Grupos de Incentivo",
      texto: "Proporcionar uma experiência única ao cliente, fazendo deste momento um acontecimento inesquecível."
    });
    this.servicos.push({
      icon: "language",
      titulo: "Agência de Turismo para congressos e feiras",
      texto: "Em feiras e congressos, planejamos toda a logística de congressistas e convidados/palestrantes do evento: passagens aéreas, hospedagem, traslados e passeios turísticos."
    });
    this.servicos.push({
      icon: "airplanemode_active",
      titulo: "Loja e Equipe no aeroporto Salgado Filho – Porto Alegre",
      texto: "Dispomos de uma loja própria no principal terminal do Aeroporto Salgado Filho, além de equipe capacitada para auxiliar todos os participantes."
    });
    this.servicos.push({
      icon: "phone",
      titulo: "Atendimento 24/7",
      texto: "Para urgências e emergências a Liga Eventos/Grupo Brocker Turismo estão prontos para atender os passageiros do seu evento 24 horas por dia, 7 dias por semana, através do seu plantão operacional."
    });
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {
    this.setServicos();
  }

}
