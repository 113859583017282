import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'unidade-item',
  templateUrl: './unidade-item.component.html',
  styleUrls: ['./unidade-item.component.scss']
})
export class UnidadeItemComponent implements OnInit {

  @Input("unidade") unidade:any = {};

  constructor() { }


  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    
  }

}
