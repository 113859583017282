import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-footer-contato',
  templateUrl: './footer-contato.component.html',
  styleUrls: ['./footer-contato.component.scss']
})
export class FooterContatoComponent implements OnInit {

  

  constructor() { }


  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {
  }

}
