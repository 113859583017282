import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'produto-item-banner',
  templateUrl: './produto-item-banner.component.html',
  styleUrls: ['./produto-item-banner.component.scss']
})
export class ProdutoItemBannerComponent implements OnInit {

  @Input("fotos") fotos = [];
  public indexShow      = 0;
  public loader         = false;

  constructor() { }

  /**
   * 
   * Prev
   * 
   * 
   */
  prev(index){

    this.loader = true;

    if((this.indexShow-1) < 0){
      this.indexShow = this.fotos.length-1;
    }else{
      this.indexShow--;
    }

    setTimeout(() => {
      this.loader = false;
    },200);

  }
  /**
   * 
   * Next
   * 
   */
  next(index){

    this.loader = true;

    if(this.indexShow == (this.fotos.length-1)){
      this.indexShow = 0;
    }else{
      this.indexShow++;
    }
    setTimeout(() => {
      this.loader = false;
    },200);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
