import { ApiSettings } from './api-settings';

export class ApiComponentesSettings {

    public static API_COMPONENTES = ApiSettings.API_BASE+"/componentes";
    public static API_PROJETOS_PROJETO_BY_APELIDO = ApiSettings.API_BASE+"/componentes/projetos/projetos";
    public static API_PROJETOS    = ApiSettings.API_BASE+"/componentes/projetos/projetos";
    public static API_PAGE        = ApiSettings.API_BASE+"/componentes/pages"; 
    public static API_EVENTO  = {
        get_evento_by_apelido: ApiSettings.API_BASE+"/componentes/evento/evento",
        get_eventos: ApiSettings.API_BASE+"/componentes/evento/eventos",
    } 
    public static EVENTO = {
        evento: ApiSettings.API_BASE+"/componentes/evento/evento",
        search: ApiSettings.API_BASE+"/componentes/evento/search"
    }
    public static PRODUTO = {
        produtos: ApiSettings.API_BASE+"/componentes/produto/produtos",
        produto: ApiSettings.API_BASE+"/componentes/produto/produto",
        search: ApiSettings.API_BASE+"/componentes/produto/search",
        avaliacao: ApiSettings.API_BASE+"/componentes/produto/avaliacao",
        avaliacoes: ApiSettings.API_BASE+"/componentes/produto/avaliacao", 
        cidade: ApiSettings.API_BASE+"/componentes/cidade",
        categorias: ApiSettings.API_BASE+"/componentes/produto/categorias",
        aeroporto: {
            combo: ApiSettings.API_BASE+"/componentes/produto/aeroporto/combo",
        },
        cia_aerea: {
            combo: ApiSettings.API_BASE+"/componentes/produto/cia-aerea/combo",
        },
        send_contato: ApiSettings.API_BASE+"/componentes/produto/send-contato",
        destino_produtos: ApiSettings.API_BASE+"/componentes/produto/destino/produtos",
        regiao_produtos: ApiSettings.API_BASE+"/componentes/produto/regiao/produtos",
        solicitacao_reserva: ApiSettings.API_BASE+"/componentes/produto/solicitacao-reserva"
    }
    public static ATIVIDADE = {
        get_atividade: ApiSettings.API_BASE+"/componentes/atividade/get-atividade",
        pagamento: ApiSettings.API_BASE+"/componentes/atividade/pagamento",
        gerar_boleto_taxas: ApiSettings.API_BASE+"/componentes/atividade/gerar-boleto-taxas",
        get_atividades_pax: ApiSettings.API_BASE+"/componentes/atividade/get-atividades-pax",
        gerar_reserva: ApiSettings.API_BASE+"/componentes/atividade/gerar-reserva" 
    }
    public static CART = {
        pagamento: ApiSettings.API_BASE+"/componentes/cart/pagamento",
        base: ApiSettings.API_BASE+"/componentes/cart",
        lista_paxs: ApiSettings.API_BASE+"/componentes/cart/lista-paxs", 
        abandonar_compra: ApiSettings.API_BASE+"/componentes/cart/abandonar-compra", 
        esvaziar: ApiSettings.API_BASE+"/componentes/cart/esvaziar",
        dados_comprador: ApiSettings.API_BASE+"/componentes/cart/dados-comprador",
        get_cart_pagamento: ApiSettings.API_BASE+"/componentes/cart/get-cart-pagamento",
        get_cart_file_gerado: ApiSettings.API_BASE+"/componentes/cart/get-cart-file-gerado",
        update_passageiro_titular: ApiSettings.API_BASE+"/componentes/cart/update-passageiro-titular"
    }
    public static MANAGE = {
        gerar_voucher: ApiSettings.API_BASE+"/componentes/manage/gerar-voucher",
        consultar_reserva: ApiSettings.API_BASE+"/componentes/manage/consultar-reserva",
        autenticar: ApiSettings.API_BASE+"/componentes/manage/autenticar",
        logout: ApiSettings.API_BASE+"/componentes/manage/logout",
        lista_tipo_servicos_by_id: ApiSettings.API_BASE+"/componentes/manage/lista-tipos-servicos-by-id",
        regioes: ApiSettings.API_BASE+"/componentes/manage/regioes",
        hoteis: ApiSettings.API_BASE+"/componentes/manage/hoteis",
        disponibilidade: ApiSettings.API_BASE+"/componentes/manage/disponibilidade"
    }
    public static PAGAMENTO = {
        finalizar_reserva: ApiSettings.API_BASE+"/componentes/pagamento/finalizar-reserva",
        enviar_confirmacao_email: ApiSettings.API_BASE+"/componentes/pagamento/enviar-confirmacao-email"
    }
    public static PEDIDO = {
        finalizar_pedido: ApiSettings.API_BASE+"/componentes/pedido/finalizar-pedido"
    }
    

}
