import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'horaVooText'
})
export class HoraVooTextPipe implements PipeTransform {

  transform(value:any,args?:any):any{
    
    value = parseInt(value); 
    let v = "";

    switch(value){
      case 1:
        v = "HORA DE CHEGADA DO VOO";
      break;
      case 2:
        v = "HORA DE PARTIDA DO VOO";
      break;
      case 3: 
        v = "HORA DO VOO";
      break;
    }
    console.log(value);
    console.log(v);
    return v;

  }

}
