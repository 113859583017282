import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-termos',
  templateUrl: './modal-termos.component.html',
  styleUrls: ['./modal-termos.component.scss']
})
export class ModalTermosComponent implements OnInit {

  public termos = "";

  constructor(
    public dialog: MatDialogRef<ModalTermosComponent>,
    @Inject(MAT_DIALOG_DATA) public data 
  ){ }

  setTermos(){
    this.termos = this.data.termos;
  }
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setTermos();
  }


}
