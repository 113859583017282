import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'passeio-item',
  templateUrl: './passeio-item.component.html',
  styleUrls: ['./passeio-item.component.scss']
})
export class PasseioItemComponent implements OnInit {

  @Input("data") data = null;
  @Output("changeValue") changeValue = new EventEmitter();
  @ViewChild("modal") modal
  public fotoCapa     = {
    imagem: "/images/brocker-eventos/brocker-eventos.jpg",
    alt: "Brocker Eventos"
  };
  public openModal        = false;
  public openModalValores = false;

  
  constructor() { }

  /**
   * 
   * Seta a capa
   * 
   */
  setCapa(){

    for(let index = 0;index < this.data.fotos.length;index++) {
      
      if(this.data.fotos[index].capa == true){

        this.fotoCapa = this.data.fotos[index];
        break;

      }
      
    }

  }
  /**
   * 
   * Open Modal
   * 
   */
  _openModal(){

    this.openModal = true;

  }
  /**
   * 
   * Open Modal
   * 
   */
  _openModalValores(){

    this.openModalValores = true; 

  }
  /**
   * 
   * 
   * @param quantidade 
   * 
   */
  adicionar(data){
  
    this.changeValue.emit({
      quantidade: data.quantidade,
      passeio: this.data
    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){  
    this.setCapa();
  }

}
