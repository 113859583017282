import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcValorServico'
})
export class CalcValorServicoPipe implements PipeTransform {

  transform(servico: any): any {

    let qtd_adt   = parseInt(servico.qtd_adt)
    let qtd_chd   = parseInt(servico.qtd_chd)
    let valor_adt = parseFloat(servico.valor_adt)
    let valor_chd = parseFloat(servico.valor_chd)
    let valor_servico = parseFloat(servico.valor_servico)
    let taxa_servico = parseFloat(servico.taxa_servico)

    return ((valor_adt*qtd_adt)+(valor_chd*qtd_chd))+valor_servico;
  }

}
