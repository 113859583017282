import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

declare var $:any;
declare var google:any;

@Component({
  selector: 'mapa-google',
  templateUrl: './mapa-google.component.html',
  styleUrls: ['./mapa-google.component.scss']
})
export class MapaGoogleComponent implements OnInit {

  
  @Input("latitude") latitude;
  @Input("longitude") longitude;
  @Input("descricao") descricao = null;
  @Input("zoom") zoom = "8";
  @Input("tipo") tipo;
  @Input("endereco") endereco;
  @Input("icon") icon = "/images/icons/sao-paulo-ecoturismo/mapa/location.svg";
  @ViewChild("maps") mapsContainer:ElementRef;
  @Input("classCustom") classCustom = "";
  
  constructor() { }

  /**
   * 
   * Cria o Google Mapa
   *  
   * 
   */
  createMapa(){
    
    let latitude  = parseFloat(this.latitude);
    let longitude = parseFloat(this.longitude);

    let map = new google.maps.Map(this.mapsContainer.nativeElement,{
      center: {
        lat: latitude, 
        lng: longitude
      },
      zoom: parseInt(this.zoom),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    
    let marker = new google.maps.Marker({
        position:{
         lat: latitude, 
         lng: longitude
        },
        icon: this.icon,
        map: map
    });
    if(this.descricao != null){
      var infowindow = new google.maps.InfoWindow({
        content: this.descricao
      });
      infowindow.open(map,marker);
    }  

    setTimeout(() => {
      google.maps.event.trigger(map,'resize');
    },3000);
  
  }
  /**
   * 
   * Retorna a posição do local baseado no endereço
   * 
   * 
   */
  getLocationByAddress(){
    
    let url = 'https://maps.google.com/maps/api/geocode/json?address='+this.endereco
    let self = this;

    $.getJSON(url, function(response) {
      
      if(response.status == "OK"){
    
        this.latitude  = response.results[0].geometry.location.lat;
        this.longitude = response.results[0].geometry.location.lng;
        self.createMapa();

      }
    
    });
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){

    if(parseInt(this.tipo) == 1){
      this.getLocationByAddress()
    }else{
      this.createMapa()
    }
    
  }

}
