import { Directive } from '@angular/core';

@Directive({
  selector: '[appTelefoneDdd]'
})
export class TelefoneDddDirective {

  constructor() { }

}
