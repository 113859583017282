import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'btn-consultar-reserva',
  templateUrl: './btn-consultar-reserva.component.html',
  styleUrls: ['./btn-consultar-reserva.component.scss']
})
export class BtnConsultarReservaComponent implements OnInit {

  @Input("file") file             = null;
  @Input("ultimonome") ultimonome = null;

  constructor(){

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){

  }

}
