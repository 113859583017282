import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'evento-item',
  templateUrl: './evento-item.component.html',
  styleUrls: ['./evento-item.component.scss']
})
export class EventoItemComponent implements OnInit {

  @Input("data") data = null;
  @ViewChild("modal") modal
  public fotoCapa     = {
    imagem: "/images/brocker-eventos/brocker-eventos.jpg",
    alt: "Brocker Eventos"
  };

  constructor() { }

  /**
   * 
   * Seta a capa
   * 
   */
  setCapa(){

    for(let index = 0;index < this.data.fotos.length;index++) {
      
      if(this.data.fotos[index].capa == true){

        this.fotoCapa = this.data.fotos[index];
        break;

      }
      
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){}

}
