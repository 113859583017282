import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../../services/app.service';

declare var $:any;

@Component({
  selector: 'hotel-modal-valores',
  templateUrl: './hotel-modal-valores.component.html',
  styleUrls: ['./hotel-modal-valores.component.scss']
})
export class HotelModalValoresComponent implements OnInit {

  @Input("hotel") hotel:any = null;
  @Input("open") open   = false;
  @ViewChild("modal") modal:ElementRef;
  @ViewChild("body") body:ElementRef;
  @Output("changeValue") changeValue = new EventEmitter();
  @Output("add") add = new EventEmitter();
  @Input("clear") clear = new EventEmitter();
  public lang = "pt";
  public title  = "";

  public fotoCapa = null;
  public fotos    = [];
  public quartos  = [];

  constructor(
    public app: AppService
  ){ 

    this.lang = this.app.getLocale();

  }
  /**
   * 
   * 
   * 
   * 
   */
  closeModal(){
    
    $(this.modal.nativeElement).modal("hide");
    this.open = false;
    this.changeValue.emit(this.open); 
    $("html").css({
      "overflow": "auto"
    });
    
  }
  /**
   * 
   * Abre o modal
   * 
   */
  openModal(){

    $(this.modal.nativeElement).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    $("html").css({
      "overflow": "hidden"
    });

  }
  /**
   * 
   * Seta as fotos
   * 
   */
  setFotos(){

    this.fotos    = [];
    this.fotoCapa = {};

    for(let index = 0; index < this.hotel.fotos.length; index++) {
      
      if(!this.hotel.fotos[index].capa){
        this.fotos.push(this.hotel.fotos[index]);
      }else{
        this.fotoCapa = this.hotel.fotos[index];
      }

    }
  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setFotos();
    this.onData();
  }
  /***
   * 
   * 
   */
  selecionar(quarto){

    let i = null;

    for(let index = 0; index < this.quartos.length; index++) {
      
      if(this.quartos[index].id == quarto.id){

        i = index;
        break;

      }
      
    }
    if(i == null){
      
      this.quartos.push(quarto);
      quarto.selecionado = true;
    
    }else{

      this.quartos.splice(i,1);
      quarto.selecionado = false;

    }

  }
  /**
   * 
   * Adicionar
   * 
   * 
   */
  adicionar(){

    this.add.emit(this.quartos);
    this.closeModal();

  }
  onData(){

    this.clear.subscribe(response => {

      this.quartos = [];

      for(let index = 0; index < this.hotel.quartos.length; index++) {
        
        this.hotel.quartos[index].selecionado = false;

      }

    });

  }
  /**
  * 
  * Verifica quando
  * 
  * @param changes 
  * 
  */
  ngOnChanges(changes: any): void {
       
    if(changes.open){
      if(changes.open.currentValue == true || changes.open.currentValue == "true"){
        this.openModal();
      }
    }
    if(changes.hotel){
      this.setFotos();
    }

  }  

}
