import { ApiComponentesSettings } from './../class/api-componentes-settings';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConteudoService {

  constructor(
    private http: HttpClient
  ) { }
  /**
   * 
   * Busca a página baseada no apelido
   * 
   * @param apelido
   * 
   */
  getPageByApelido(apelido,params=null):any{
    
    let url = ApiComponentesSettings.API_PAGE+"/"+apelido;
    
    return this.http.get(url,{params})
    
  }

}
