import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'combo-validade-mes',
  templateUrl: './combo-validade-mes.component.html',
  styleUrls: ['./combo-validade-mes.component.scss']
})
export class ComboValidadeMesComponent implements OnInit {

  @Input("label") label             = null;
  @Output("changeValue") changeValue = new EventEmitter();
  public value:any = "";
  public mes = [];

  constructor() { }

  /**
   * 
   * Atualiza o valor ao clicar na opção
   * 
   * @param value 
   * 
   */
  change(value){

    this.changeValue.emit(this.value);

  }
  clickValue(value){
    this.changeValue.emit(this.value);
  }
  setMeses(){

    this.mes = [
      "01","02","03","04","05","06","07","08","09","10","11","12"
    ]

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    this.setMeses();
  }

}
