import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-contatos-home',
  templateUrl: './contatos-home.component.html',
  styleUrls: ['./contatos-home.component.scss']
})
export class ContatosHomeComponent implements OnInit {


  constructor() { }

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
