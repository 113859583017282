import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-produto-cidade-form',
  templateUrl: './produto-cidade-form.component.html',
  styleUrls: ['./produto-cidade-form.component.scss']
})
export class ProdutoCidadeFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
