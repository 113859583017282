import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'passeios-page',
  templateUrl: './passeios-page.component.html',
  styleUrls: ['./passeios-page.component.scss']
})
export class PasseiosPage implements OnInit {

  public loader  = false;
  public data    = [];
  public apelido = ""; 

  constructor(
    private _api: ApiService,
    private _app: AppService
  ){ }

  /**
   * 
   * Retorna os dados
   * 
   */
  getData(){

    try{

      this.loader = true;

      /*this._api.produto().produtos().subscribe(response => {



      },(response) => {

      });*/

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Inicializa as Funçõe
   * 
   */
  ngOnInit(){
    this.getData();
  }

}
