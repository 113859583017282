import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty'
})
export class EmptyPipe implements PipeTransform {

  transform(value: any,s: any): any {
    
    let sub = typeof(s) == "undefined" ? "---" : s;

    if(typeof(sub) != "undefined"){
      
      return (value === null || value === "" || value.length == 0) ? sub : value;
    
    }else{

      return sub;

    }  

  }

}
