import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'combo-validade-ano',
  templateUrl: './combo-validade-ano.component.html',
  styleUrls: ['./combo-validade-ano.component.scss']
})
export class ComboValidadeAnoComponent implements OnInit {
  
  @Input("label") label = null;
  @Output("changeValue") change = new EventEmitter();
  public years = [];
  public value = "";

  constructor() { }
  
  /**
   * 
   * Popula o array de anos
   * 
   * 
   */
  popularArrayYear(){
    
    let data = new Date()
    
    for (var index = 0; index < 25; index++) {
          
      this.years.push(data.getFullYear()+index);
          
    }
    
  }
  /**
   * 
   * Atualiza o valor ao clicar na opção
   * 
   * @param value 
   * 
   */
  clickValue(value){

    this.change.emit(value); 
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {
    this.popularArrayYear();
  }

}
