import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'evento-banner',
  templateUrl: './evento-banner.component.html',
  styleUrls: ['./evento-banner.component.css']
})
export class EventoBannerComponent implements OnInit {

  @Input("banner") banner = null;

  

  constructor() { }

  /**
   * 
   * Iniciaza as Funções
   * 
   */
  ngOnInit() {
  }

}
