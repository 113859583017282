import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'divisor-title',
  templateUrl: './divisor-title.component.html',
  styleUrls: ['./divisor-title.component.scss']
})
export class DivisorTitleComponent implements OnInit {

  @Input("classCustom") classCustom = "";

  constructor(){ }
  
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
  }

  
}
