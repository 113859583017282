import { Injectable } from '@angular/core';
import { AppService } from './app.service';

@Injectable()
export class DateService {

  constructor(
    private app: AppService
  ) { }

  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonthString(value){

    let data  = new Date(value);
    let month = data.getUTCMonth()+1;
    let out   = "";
    let lg = this.app.getLocale(); 

    switch(month){
      case 1:
        if(lg == "pt"){
          out = "Janeiro";
        }else if(lg == "en"){
          out = "January";
        }else{
          out = "Enero";
        }
      break;
      case 2:
        if(lg == "pt"){
          out = "Fevereiro";
        }else if(lg == "en"){
          out = "February";
        }else{
          out = "Febrero";
        }
      break;
      case 3:
        if(lg == "pt"){
          out = "Março";
        }else if(lg == "en"){
          out = "March";
        }else{
          out = "Maezo";
        }
      break;
      case 4:
        if(lg == "pt"){
          out = "Abril";
        }else if(lg == "en"){
          out = "April";
        }else{
          out = "Abril";
        }
      break;
      case 5:
        if(lg == "pt"){
          out = "Maio";
        }else if(lg == "en"){
          out = "May";
        }else{
          out = "Mayo";
        }
      break;
      case 6:
        if(lg == "pt"){
          out = "Junho";
        }else if(lg == "en"){
          out = "June";
        }else{
          out = "Junio";
        }
      break;
      case 7:
        if(lg == "pt"){
          out = "Julho";
        }else if(lg == "en"){
          out = "July";
        }else{
          out = "Julio";
        }
      break;
      case 8:
        if(lg == "pt"){
          out = "Agosto";
        }else if(lg == "en"){
          out = "August";
        }else{
          out = "Agosto";
        }
      break;
      case 9:
        if(lg == "pt"){
          out = "Setembro";
        }else if(lg == "en"){
          out = "September";
        }else{
          out = "Septiembre";
        }
      break;
      case 10:
        if(lg == "pt"){
          out = "Outubro";
        }else if(lg == "en"){
          out = "October";
        }else{
          out = "Octubre";
        }
      break;
      case 11:
        if(lg == "pt"){
          out = "Novembro";
        }else if(lg == "en"){
          out = "November";
        }else{
          out = "Noviembre";
        }
      break;
      case 12:
        if(lg == "pt"){
          out = "Dezembro";
        }else if(lg == "en"){
          out = "December";
        }else{
          out = "Diciembre";
        }
      break;
    }

    return out;

  }
  /**
   * 
   * Retorna o dia da data
   * 
   */
  getDay(value){

    let data = new Date(value);
    
    return data.getUTCDate() < 10 ? "0"+data.getUTCDate() : data.getUTCDate();

  }
  /**
   * 
   * Retorna o ano da data
   * 
   */
  getYear(value){
    
    let data = new Date(value);
        
    return data.getFullYear();
    
  }
  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonth(value){
    
    let data  = new Date(value);
    let month = data.getUTCMonth()+1; 
    let out   = month < 10 ? "0"+month : month;
    
    return out;
    
  }
  /**
   * 
   * Formata a data
   * 
   * @param data 
   * 
   */
  formatDateFromDatepicker(data){

    let date = new Date(data);

    let dia     = date.getDate();
    let mes:any = date.getUTCMonth()+1;
    mes         = mes < 10 ? "0"+mes : mes;
    let ano     = date.getFullYear();

    return ano+"-"+mes+"-"+dia;

  }
   /***
   * 
   * Format date
   * 
   ***/
   formatDate(date){

    let data = date.split("-");

    return data[2]+"/"+data[1]+"/"+data[0];

  }

}
