import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-selo-trip',
  templateUrl: './selo-trip.component.html',
  styleUrls: ['./selo-trip.component.scss']
})
export class SeloTripComponent implements OnInit {

  @Input("data") data;
  @Input("template") template;
  @Input("classCustom") classCustom;

  constructor() { }

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
