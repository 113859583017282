import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, EventEmitter, Output } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent implements OnInit,OnChanges {

  @Input("title")     title         = "Atenção";
  @Input("show")      show          = false;
  @Input("message")   message       = ""; 
  @Input("customClass") customClass = "";
  @Output("hide")     hide          = new EventEmitter();
  @ViewChild("modal") modal:ElementRef;

  constructor() { }

  /**
   * 
   * Fecha o modal
   * 
   */
  hideModal(){
    this.hide.emit();
    $(this.modal.nativeElement).modal("hide");
    $("#page-header").removeClass("modal-opened");
    $("#page-footer").removeClass("modal-opened");
  }
  /**
   * 
   * Abre o Modal
   * 
   */
  showModal(){
    $(this.modal.nativeElement).modal("show");
    $("#page-header").addClass("modal-opened");
    $("#page-footer").addClass("modal-opened");
  }
  /**
   * 
   * Test
   * 
   */
  _click(){

    this.hide.emit();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    
  }
  /**
   * 
   * Verifica a alteração de estado das variáveis
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any): void {
    
    if(changes.show){

      if(changes.show.currentValue == true || changes.show.currentValue == 'true'){
        this.showModal();
      }

    }

  }

}
