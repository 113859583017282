import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'linguagem-select',
  templateUrl: './linguagem-select.component.html',
  styleUrls: ['./linguagem-select.component.scss']
})
export class LinguagemSelectComponent implements OnInit {

  

  constructor(){ }

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
