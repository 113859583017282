import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-combo-manager-aeroporto',
  templateUrl: './combo-manager-aeroporto.component.html',
  styleUrls: ['./combo-manager-aeroporto.component.scss']
})
export class ComboManagerAeroportoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
