import { Component, OnInit } from '@angular/core';
import { ConteudoService } from '../../services/conteudo.service';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'cliente-page',
  templateUrl: './cliente-page.component.html',
  styleUrls: ['./cliente-page.component.scss']
})
export class ClientePage implements OnInit {

  public loader   = false;
  private apelido = "cliente";
  public response:any = {
    data: "",
    status: null
  };
  public page      = null;
  public fotos     = []; 
  public logos     = [];
  public params    = {};
  public clientes  = [];

  constructor(
    private conteudo:ConteudoService,
    private app: AppService
  ) { }

  /**
   * 
   * 
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true;
      
      this.conteudo.getPageByApelido(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params    = JSON.parse(this.page.params);
            this.clientes  = response.data.clientes;
            this.logos     = response.data.logos;
            this.fotos     = response.data.page.fotos;
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
              
          }
          this.response = {
            data: "",
            status: 1
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.getData();
  }

}
