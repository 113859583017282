import { ApiSettings } from './api-settings';

export class ApiModulosSettings {


    public static API_MODULOS                  = ApiSettings.API_BASE+"/modulo/modulo";
    public static API_MODULO_CONTATO_SEND      = ApiSettings.API_BASE+"/modulo/contato/send";
    public static API_MODULO_NEWSLETTER_CREATE = ApiSettings.API_BASE+"/modulo/newsletter";
    public static API_MODULO_PRODUTOS = {
        produtos_categoria: ApiSettings.API_BASE+"/modulo/produtos/categoria"
    }
    public static API_MODULO_FOTOS_BUSTOUR  = ApiSettings.API_BASE+"/modulo/fotos-bustour";
    public static API_MODULO_EVENTOS = {
        proximos_eventos: ApiSettings.API_BASE+"/modulo/eventos/proximos"
    }
    public static CONTATO = {
        contato_viagem: ApiSettings.API_BASE+"/modulo/contato/viagem",
        fale_conosco: ApiSettings.API_BASE+"/modulo/contato/fale-conosco" 
    }

}
