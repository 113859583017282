import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { HotelModalDetalheComponent } from '../hotel-modal-detalhe/hotel-modal-detalhe.component';

declare var $:any;

@Component({
  selector: 'hospedagem-item-two',
  templateUrl: './hospedagem-item-two.component.html',
  styleUrls: ['./hospedagem-item-two.component.scss']
})
export class HospedagemItemTwo implements OnInit {

  @Input("data") data                = null;
  @Output("changeValue") changeValue = new EventEmitter(); 
  @Input("clearInput") clearInput    = new EventEmitter();
  @Output("next") next               = new EventEmitter(); 
  public clear                       = new EventEmitter();  
  public quarto                      = null; 
  public quarto_id                   = null; 
  public quantidade:any              = 1;
  public quantidade_chd:any          = 0;
  @Input("dias") dias:any            = 1; 
  @Input("clearAll") clearAll        = new EventEmitter(); 
  public prefixoValor                = "R$";  

  public fotoCapa     = {
    imagem: "/images/brocker-eventos/brocker-eventos.jpg",
    alt: "Brocker Eventos"
  };
  public estrelas         = [];
  public locale           = "pt";
  public openModal        = false; 
  public openModalValores = false;
  public isSelecionado    = false;
  public valor            = 0;

  constructor(
    private app: AppService,
    private dialog: MatDialog
  ){ 

    this.locale = this.app.getLocale();

  }
  /**
   * 
   * Next
   * 
   */
  _next(){

    this.next.emit();

  }
  /**
   * 
   * Seta a capa
   * 
   */
  setCapa(){

    for(let index = 0;index < this.data.fotos.length;index++) {
      
      if(this.data.fotos[index].capa == true){

        this.fotoCapa = this.data.fotos[index];
        break;

      }
      
    }

  }
  /**
   * 
   * On change
   * 
   */
  onChange(){

    this.changeValue.emit({  
      hotel: this.data,
      quarto: this.quarto,
      quantidade: this.quantidade, 
      quantidade_chd: this.quantidade_chd 
    }); 
    
  }
  /**
   * 
   * 
   * 
   */
  setEstrelas(){

    for (let index = 0; index < this.data.estrelas; index++) {
      
      this.estrelas.push(index);
      
    }

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.clearInput.subscribe((hotel_id) => {

      if(hotel_id != this.data.id){

        this.clear.emit();

      }

    });
    this.clearAll.subscribe(() => {

      this.isSelecionado  = false;
      this.quarto         = null;
      this.quarto_id      = null;
      this.valor          = 0; 

    });

  }
  /**
   * 
   * Open modal
   * 
   */
  _openModal(){

    this.dialog.open(
      HotelModalDetalheComponent 
    ,{
      width: "600px",
      data: {
        hotel: this.data
      },
      panelClass: 'custom-dialog-container'
    });

  }
  /**
   * 
   * Open modal
   * 
   */
  _openModalValores(){

    this.openModalValores = true;

  }
  /***
   * 
   * 
   * 
   */
  adicionar(quartos){

    this.changeValue.emit({
      hotel: this.data,
      quartos: quartos
    }); 

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  _change(){

    let id = this.quarto_id;
    
    for(let index = 0;index < this.data.quartos.length;index++){
      
      if(this.data.quartos[index].id == id){

        this.quarto = this.data.quartos[index];

      }
      
    }
    let valor  = (parseFloat(this.quarto.valor)*parseFloat(this.dias));
    
    this.valor = valor; 

    if(this.isSelecionado){
      this.onChange();
    }

  }
  /**
   * 
   * Selecionar
   * 
   */
  selecionar(deletar=false){

    this.changeValue.emit({
      hotel: this.data,
      quarto: this.quarto,
      quantidade: this.quantidade,
      quantidade_chd: this.quantidade_chd,
      deletar: deletar 
    }); 
    if(!deletar){

      this.isSelecionado = true;

    }else{

      this.isSelecionado = false;

    }

  }
  /**
   * 
   * Next
   * 
   */
  nextSection(){

    this.next.emit();

  }
  /**
   * 
   * Setar o prefixo
   * 
   */
  setPrefixo(){

    if(this.locale == "en" || this.locale == "es"){
      this.prefixoValor = "US$";
    }else{
      this.prefixoValor = "R$";  
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setCapa();
    this.setEstrelas();
    this.onData();
    this.setPrefixo();
  }

}
