import { AppSettings } from './../../../class/app-settings';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  @Input("data") data;
  public alt = AppSettings.NAME;
  public logo = null;

  constructor() { }

  setLogo(){

    this.logo = "/images/modulo/logo/"+this.data.params.imagem;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLogo();
  }

}
