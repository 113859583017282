import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'divisor-full',
  templateUrl: './divisor-full.component.html',
  styleUrls: ['./divisor-full.component.scss']
})
export class DivisorFullComponent implements OnInit {

  
  constructor() { }


  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){

  }

}
